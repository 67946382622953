import Supports from "./supports.svg";

export const TopSection: React.FC = () => {
  return (
    <div className="hero bg-base-100 py-16">
      <div className="hero-content max-w-full flex-col lg:flex-row-reverse">
        <img src={Supports} className="rounded-lg" alt="catch-copy" />
        <div className="ml-16">
          <h1 className="text-4xl font-bold">
            専任スタッフによる安心サポート！
          </h1>
          <p className="py-6">
            自由な働き方へのステップアップをサポート。専任スタッフがあなたを支えます
          </p>
        </div>
      </div>
    </div>
  );
};
