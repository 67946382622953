import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useRef } from "react";
import { DropdownMenuItemUI } from "./DropdownMenuItem";
import { useHistory } from "react-router-dom";

export type DropdownMenuUIProps = {
  visibility: boolean;
  onHidden: () => void;
};

export const DropdownMenuUI: React.FC<DropdownMenuUIProps> = ({
  visibility,
  onHidden,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const onBlurHandler = useCallback(
    event => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        onHidden();
      }
    },
    [onHidden]
  );

  // const onClickItemHandler: React.MouseEventHandler<HTMLLIElement> =
  //   useCallback(event => {
  //     event.stopPropagation();
  //     ref.current && ref.current.blur();
  //   }, []);

  useEffect(() => {
    visibility && ref.current && ref.current.focus();
  }, [visibility]);
  const logout = async () => {
    await Auth.signOut();
    history.push("/login");
  };
  if (!visibility) {
    return <></>;
  }
  return (
    <div
      ref={ref}
      className="absolute top-16 right-0 w-36 text-xs border border-blue focus:outline-none"
      tabIndex={1}
      onBlur={onBlurHandler}
    >
      <ul>
        {/* <DropdownMenuItemUI to={"/profile"} onClick={onClickItemHandler}>
          プロフィール設定
        </DropdownMenuItemUI> */}
        <DropdownMenuItemUI onClick={logout}>ログアウト</DropdownMenuItemUI>
      </ul>
    </div>
  );
};
