import React, { useState } from "react";
// import { ReactComponent as Avator } from "./avator.svg";
import { LayoutMeFragment } from "../../generated/graphql";
import { DropdownMenuUI } from "../DropdownMenu";

export type HeaderUIProps = {
  me?: LayoutMeFragment;
};

export const HeaderUI: React.VFC<HeaderUIProps> = ({ me }) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const onClickAvator = () => {
    setShowDropdownMenu(true);
  };

  const onHiddenDropdownMenu = () => {
    setShowDropdownMenu(false);
  };
  return (
    <header className="flex flex-none justify-between items-center h-16 px-5 bg-gradient-to-r from-sidebar-left to-sidebar-right">
      <div className="text-2xl font-bold text-white">S t r i x</div>
      {me && (
        <div
          className="flex flex-row items-center space-x-4 h-full relative cursor-pointer"
          onClick={onClickAvator}
        >
          {/* <Avator></Avator> */}
          <span className="text-lg text-white" data-testid="header-username">
            {me.familyName} {me.givenName}
          </span>
          <DropdownMenuUI
            visibility={showDropdownMenu}
            onHidden={onHiddenDropdownMenu}
          />
        </div>
      )}
    </header>
  );
};
