import { HTMLAttributes } from "react";
import ImgFeelingProud from "./asset/feeling_proud.svg";
import ImgGeniuses from "./asset/geniuses.svg";
import ImgInstantSupport from "./asset/instant_support.svg";
import ImgBestPlace from "./asset/best_place.svg";
import { ReasonItemLeft, ReasonItemProps, ReasonItemRight } from "./ReasonItem";
import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";

type ReasonsProps = HTMLAttributes<HTMLDivElement>;

export const Reasons: React.FC<ReasonsProps> = ({ ...rest }) => {
  const items: ReasonItemProps[] = [
    {
      imgPath: ImgFeelingProud,
      title: "安定して案件が獲得できる理由",
      texts: [
        "エンジニア職は常に不足しており、エンジニア歴1年以上経験されている方であればほとんどの確率で年収が上がります。",
      ],
    },
    {
      imgPath: ImgBestPlace,
      title: "地方移住しても生活が安定する理由",
      texts: [
        `地方のエンジニアの給与が上がりにくいのは、地方ほど下請けのIT企業が多いことと関係があります。
      元々は大手から大きな月額報酬が振り込まれていたとしても、元請け、二次受け、三次受けと、どんどん商流が深くなるにつれ、
      エンジニアの実力とは関係のないところでエンジニアの収入の上限は下がっていってしまいます。
      弊社ではクライアント直の案件や一次請けに絞って営業活動を行っていますが、それでも毎月数百件の開発案件をやり取りしています。`,
      ],
    },
    {
      imgPath: ImgInstantSupport,
      title: "地方移住経験者による体験に基づいた安心サポート",
      texts: [
        `弊社はもともと代表が会社員からフリーランスに転向した経緯があり、
      その体験を基に多くのフリーランスエンジニアを排出してきました。
      また、フルリモートで開発するノウハウも豊富なため、より具体的な相談やお悩みを解決することができます。`,

        `また、弊社では、リモート化、電子化、自動化を徹底しています。自社業務はフルリモート体制で運用しており、
      事務所費など利用頻度が低いものは最小限に抑えています。またいはゆるペーパレス化も徹底しており、契約書や書類の授受はすべて電子媒体やCloud Serviceを利用して行っています。
      また、事務手続き(必要書類の作成)は概ねボット化や自動化に取り組んでおり、一番コストのかかる人件費を最小限に抑える取り組みを常に強化しています。
      また、代表自身も現役でエンジニアとして自分の給料を自分で稼いでいるので会社の運用費や給料をエンジニアの手数料（マージン）に依存しなくて良いという点も他社と比較して高単価を提供できる理由の一つです。`,
      ],
    },
  ];

  return (
    <div
      className="px-12 bg-base-100 pt-6 flex flex-col items-center pb-12"
      {...rest}
    >
      <img className="w-48 h-48" src={ImgGeniuses} alt="案件紹介" />
      <SectionTitle>地方ワークが安定する3つの理由</SectionTitle>
      <div className="flex flex-wrap justify-between w-full gap-8">
        {items.map((item, index) =>
          index % 2 === 0 ? (
            <ReasonItemRight
              key={`right_item_${index}`}
              imgPath={item.imgPath}
              title={item.title}
              texts={item.texts}
            />
          ) : (
            <ReasonItemLeft
              key={`left_item_${index}`}
              imgPath={item.imgPath}
              title={item.title}
              texts={item.texts}
            />
          )
        )}
      </div>
    </div>
  );
};
