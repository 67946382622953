import React from "react";
import { HeaderUI } from "./Header";
import { LayoutMeFragment } from "../../generated/graphql";

export type HeaderContainerProps = {
  me?: LayoutMeFragment;
};

const HeaderContaier: React.VFC<HeaderContainerProps> = ({ me }) => {
  return <HeaderUI me={me} />;
};

export default HeaderContaier;
