import React, { InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";
import { OptionItem } from "../../../types/common";

export type SelectUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  initialOption: string;
  options: OptionItem[];
} & InputHTMLAttributes<HTMLInputElement>;

export const SelectUI: React.FC<SelectUIProps> = ({
  registerName,
  initialOption,
  options,
  className,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[registerName];
  return (
    <div className={`${className}`}>
      <select
        {...register(registerName)}
        className={`select ${
          error ? "select-error" : "select-accent"
        } bg-white w-full`}
        defaultValue=""
      >
        <option disabled value="">
          {initialOption}
        </option>
        {options.map((option: OptionItem, index: number) => (
          <option key={`${option.value}_${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className="text-error text-xs">{error.message}</span>}
    </div>
  );
};
