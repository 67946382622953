import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormGroupLabelUI } from "../../components/FormGroupLabel";
import { InputUI } from "../../components/rhfs/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { object as yupObject, string as yupString } from "yup";
import "yup-phone";
import { PageTitleUI } from "../../components/PageTitle";
import { ContactInput } from ".";
import { QRCard } from "./components/QRCard/QRCard";
import { TextareaUI } from "../../components/rhfs/Textarea";

type ContactPageProps = {
  contactInput: ContactInput;
  onContact: SubmitHandler<ContactInput>;
};

export const ContactPage = ({ onContact }: ContactPageProps) => {
  const methods = useForm<ContactInput>({
    resolver: yupResolver(
      yupObject({
        name: yupString().required("お名前は必須項目です"),
        email: yupString()
          .email("メールアドレスの形式が不正です")
          .required("メールアドレスは必須項目です"),
        subject: yupString().required("件名は必須項目です"),
        message: yupString().required("お問い合わせ内容は必須項目です"),
      }).required()
    ),
  });
  return (
    <div className="">
      <PageTitleUI className="my-10">お問い合わせ</PageTitleUI>
      <div className="w-3/5 mx-auto">
        <div className="py-20 bg-white px-16">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onContact)}>
              <div className="flex">
                {/* 左列 */}
                <div className="w-1/3 flex-col space-y-10">
                  {[
                    { label: "お名前", required: true },
                    { label: "メールアドレス", required: true },
                    { label: "件名", required: true },
                    { label: "お問い合わせ内容", required: true },
                  ].map((form, index) => (
                    <FormGroupLabelUI
                      key={index}
                      required={form.required}
                      className="flex items-center h-16"
                    >
                      {form.label}
                    </FormGroupLabelUI>
                  ))}
                </div>
                {/* 右列 */}
                <div className="w-2/3 flex-col space-y-10">
                  {/* お名前 */}
                  <div className="flex space-x-3 items-center h-16 justify-end">
                    <InputUI
                      registerName="name"
                      placeholder="お名前"
                      className="w-full"
                    />
                  </div>
                  {/* メールアドレス */}
                  <div className="flex items-center justify-end">
                    <InputUI
                      registerName="email"
                      placeholder="メールアドレス"
                      className="w-full"
                    />
                  </div>
                  {/* 件名 */}
                  <div className="flex items-center justify-end">
                    <InputUI
                      registerName="subject"
                      placeholder="件名"
                      className="w-full"
                    />
                  </div>
                  {/* お問い合わせ内容 */}
                  <div className="flex items-center h-40 justify-end">
                    <TextareaUI
                      registerName="message"
                      placeholder="お問い合わせ内容"
                      className="w-full h-40"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-12">
                <button className="btn btn-accent btn-wide">送信する</button>
              </div>
            </form>
          </FormProvider>
          <QRCard />
        </div>
      </div>
    </div>
  );
};
