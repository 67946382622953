import QrMitsuhiro from "./assets/qr_line_mitsuhiro.jpg";

export const QRCard: React.FC = () => {
  return (
    <div className="px-10 pt-10">
      <h1 className="text-3xl text-center font-bold">
        LINEでもお問い合わせいただけます！
      </h1>
      <div className="flex flex-col lg:flex-row">
        <div className="w-1/3 flex justify-center">
          <img
            src={QrMitsuhiro}
            className="h-24 w-24 rounded-lg shadow-2xl"
            alt="qrmitsuhiro"
          />
        </div>
        <div className="w-2/3">
          <p className="py-6">
            左記のQRコードから友だち登録し、メッセージいただいても
            <br />
            エージェントと直接やりとりできます。
          </p>
        </div>
      </div>
    </div>
  );
};
