import { HTMLAttributes } from "react";
import { UserVoiceItem, UserVoiceItemProps } from "./UserVoiceItem";
import izumiIcon from "./asset/izumi.jpg";
import AndoIcon from "./asset/ando.jpg";
import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";

type UserVoiceProps = HTMLAttributes<HTMLDivElement>;

export const UserVoice: React.FC<UserVoiceProps> = ({ ...rest }) => {
  const UserVoiceItems: UserVoiceItemProps[] = [
    {
      icon: AndoIcon,
      title: "Q. Region Hackの良いところを教えてください。",
      text: "1社目を退社するきっかけとなった案件選択の自由が、技術者側にあることです。\n案件を捜してくれるのは営業側ですが、その際に条件を細かく提示できることと、実際に面談を受けてみてその案件に参画するか否かの決定権が技術者側にあるのは好印象です。\nまた、2社目を退職するきっかけとなった金銭的な問題でも、契約内容を提示して頂いていることと、そこから自分の給与となる割合を明言されているため、単価が上がることがそのまま給与があがることにつながり、働くことへのモチベーションに大きく影響しています。\nまた、会社自体がリモートワークに理解があり、条件に含めることに問題がないことも良いと考えています。",
      profile: "フルスタックエンジニア・男性",
    },
    {
      icon: izumiIcon,
      title:
        "Q. 率直にフリーランスエンジニアになってよかったですか？理由も教えてください。",
      text: "案件の移り変わりが早いのでいろんな現場や技術を経験でき、スキルアップに繋がることと、スキルアップした自分の技術力が次の案件の単価に反映されるので自分の市場価値を理解しながら仕事ができることが良いことだなと思います。\n会社員では見えなかったところが見えるようになり、目標を立てやすくなったのでフリーランスになったことでより意欲的に仕事に取り組めるようになったので良かったです。",
      profile: "フロントエンドエンジニア・男性",
    },
  ];

  return (
    <div
      className="px-12 bg-base-200 pt-12 flex flex-col items-center"
      {...rest}
    >
      <SectionTitle>利用した方の声</SectionTitle>
      <div className="flex flex-wrap justify-between w-full gap-4">
        {UserVoiceItems.map((item, index) => (
          <UserVoiceItem
            key={`item_${index}`}
            icon={item.icon}
            title={item.title}
            text={item.text}
            profile={item.profile}
          />
        ))}
      </div>
    </div>
  );
};
