import React from "react";

export type CardProps = {
  cardImg?: string;
  title?: string;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Card: React.FC<CardProps> = ({
  title,
  className,
  cardImg,
  children,
}) => {
  return (
    <div className={`card bg-base-100 shadow-xl ${className}`}>
      {title && (
        <>
          <h2 className="card-title line-clamp-2 px-4">{title}</h2>
          <div className="divider"></div>
        </>
      )}
      {cardImg && (
        <img src={cardImg} alt="card" className="rounded-xl h-48 px-10 pt-10" />
      )}
      {children}
    </div>
  );
};
