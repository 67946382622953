import React from "react";
import { SupportsPage, SupportType } from "./SupportsPage";
import { useLocation } from "react-router-dom";

const SupportsPageContainer: React.FC = () => {
  const { state } = useLocation<{
    supportType: SupportType | undefined;
  }>();
  return <SupportsPage initSupportType={state?.supportType} />;
};

export default SupportsPageContainer;
