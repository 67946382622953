import { SignUpInput } from "../../hooks/authProvider/authProvider";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormGroupLabelUI } from "../../components/FormGroupLabel";
import { InputUI } from "../../components/rhfs/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { object as yupObject, string as yupString } from "yup";
import "yup-phone";
import { StepsUI } from "./components/Steps";
import { PageTitleUI } from "../../components/PageTitle";
import { SelectUI } from "../../components/rhfs/Select";

type SignUpPageProps = {
  signUpInput: SignUpInput;
  onSignUp: SubmitHandler<SignUpInput>;
};

export const SignUpPage = ({ onSignUp }: SignUpPageProps) => {
  const yearOptions = [...Array(100).keys()].map(number => {
    const year = number + 1930;
    return {
      label: `${year}年`,
      value: `${year}`,
    };
  });
  const monthOptions = [...Array(12).keys()].map(number => {
    const month = number + 1;
    return {
      label: `${month}月`,
      value: `${month}`,
    };
  });
  const dayOptions = [...Array(31).keys()].map(number => {
    const month = number + 1;
    return {
      label: `${month}日`,
      value: `${month}`,
    };
  });

  const methods = useForm<SignUpInput>({
    resolver: yupResolver(
      yupObject({
        familyName: yupString().required("姓は必須項目です"),
        givenName: yupString().required("名は必須項目です"),
        email: yupString()
          .email("メールアドレスの形式が不正です")
          .required("メールアドレスは必須項目です"),
        birthYear: yupString().nullable(false).required("誕生年は必須項目です"),
        birthMonth: yupString().required("誕生月は必須項目です"),
        birthDay: yupString().required("誕生日は必須項目です"),
        tel: yupString()
          .phone("IN", false, "適切な電話番号を入力してください")
          .required("電話番号は必須項目です"),
        job: yupString().required("職業は必須項目です"),
        prefecture: yupString().required("お住まいの都道府県は必須項目です"),
        password: yupString().required("パスワードは必須項目です"),
      }).required()
    ),
  });
  return (
    <div className="">
      <PageTitleUI className="my-10">会員登録</PageTitleUI>
      <div className="w-3/5 mx-auto">
        <StepsUI
          steps={[
            { name: "アカウントの作成", checked: true },
            { name: "登録完了", checked: false },
          ]}
        />
        <div className="py-20 bg-white px-16">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSignUp)}>
              <div className="flex">
                {/* 左列 */}
                <div className="w-1/3 flex-col space-y-10">
                  {[
                    { label: "氏名", required: true },
                    { label: "ふりがな", required: false },
                    { label: "メールアドレス", required: true },
                    { label: "生年月日", required: true },
                    { label: "電話番号", required: true },
                    { label: "職業", required: true },
                    { label: "お住まいの都道府県", required: true },
                    { label: "パスワード", required: true },
                  ].map((form, index) => (
                    <FormGroupLabelUI
                      key={index}
                      required={form.required}
                      className="flex items-center h-16"
                    >
                      {form.label}
                    </FormGroupLabelUI>
                  ))}
                </div>
                {/* 右列 */}
                <div className="w-2/3 flex-col space-y-10">
                  {/* 氏名 */}
                  <div className="flex space-x-3 items-center h-16 justify-end">
                    <InputUI
                      registerName="familyName"
                      placeholder="姓"
                      className="w-full"
                    />
                    <InputUI
                      registerName="givenName"
                      placeholder="名"
                      className="w-full"
                    />
                  </div>
                  {/* ふりがな */}
                  <div className="flex space-x-3 items-center h-16 justify-end">
                    <InputUI
                      registerName="familyNameKana"
                      placeholder="せい"
                      className="w-full"
                    />
                    <InputUI
                      registerName="givenNameKana"
                      placeholder="めい"
                      className="w-full"
                    />
                  </div>
                  {/* メールアドレス */}
                  <div className="flex items-center h-16 justify-end">
                    <InputUI
                      registerName="email"
                      placeholder="メールアドレス"
                      className="w-full"
                    />
                  </div>
                  {/* 生年月日 */}
                  <div className="flex items-center space-x-3 h-16 justify-end">
                    <SelectUI
                      registerName="birthYear"
                      initialOption="年"
                      className="w-full"
                      options={yearOptions}
                    />
                    <SelectUI
                      registerName="birthMonth"
                      initialOption="月"
                      className="w-full"
                      options={monthOptions}
                    />
                    <SelectUI
                      registerName="birthDay"
                      initialOption="日"
                      className="w-full"
                      options={dayOptions}
                    />
                  </div>
                  {/* 電話番号 */}
                  <div className="flex items-center h-16 justify-end">
                    <InputUI
                      registerName="tel"
                      placeholder="08055551111"
                      className="w-full"
                    />
                  </div>
                  {/* 職種 */}
                  <div className="flex items-center h-16 justify-end">
                    <SelectUI
                      registerName="job"
                      className="w-full"
                      initialOption="選択してください"
                      options={[
                        "バックエンドエンジニア",
                        "フロントエンジニア",
                        "iOSエンジニア・Androidエンジニア",
                        "ゲームプログラマ・エンジニア",
                        "インフラエンジニア",
                        "セキュリティエンジニア",
                        "テストエンジニア・テクニカルサポート",
                        "Webコーダー",
                        "Webライター",
                        "編集者・翻訳・その他ライター",
                        "Webデザイナー・UI/UXデザイナー",
                        "アートディレクター・クリエイティブディレクター",
                        "グラフィックデザイナー・CGデザイナー・イラストレーター",
                        "Webディレクター・プロデューサー・プロジェクトマネージャー",
                        "データアナリスト・データサイエンティスト",
                        "システムコンサルタント・ITコンサルタント",
                        "マーケター・メディア運用",
                        "DX推進",
                        "営業・企画・コンサルティング",
                        "採用・組織開発・制度設計",
                      ].map((job: string) => ({ label: job, value: job }))}
                    />
                  </div>
                  {/* お住まいの都道府県 */}
                  <div className="flex items-center h-16 justify-end">
                    <SelectUI
                      registerName="prefecture"
                      className="w-full"
                      initialOption="選択してください"
                      options={[
                        "北海道",
                        "青森県",
                        "岩手県",
                        "宮城県",
                        "秋田県",
                        "山形県",
                        "福島県",
                        "茨城県",
                        "栃木県",
                        "群馬県",
                        "埼玉県",
                        "千葉県",
                        "東京都",
                        "神奈川県",
                        "新潟県",
                        "富山県",
                        "石川県",
                        "福井県",
                        "山梨県",
                        "長野県",
                        "岐阜県",
                        "静岡県",
                        "愛知県",
                        "三重県",
                        "滋賀県",
                        "京都府",
                        "大阪府",
                        "兵庫県",
                        "奈良県",
                        "和歌山県",
                        "鳥取県",
                        "島根県",
                        "岡山県",
                        "広島県",
                        "山口県",
                        "香川県",
                        "愛媛県",
                        "高知県",
                        "福岡県",
                        "佐賀県",
                        "長崎県",
                        "熊本県",
                        "大分県",
                        "宮崎県",
                        "鹿児島県",
                        "沖縄県",
                      ].map((prefecture: string) => ({
                        label: prefecture,
                        value: prefecture,
                      }))}
                    />
                  </div>
                  {/* パスワード */}
                  <div className="flex items-center h-16 justify-end">
                    <InputUI
                      type="password"
                      registerName="password"
                      placeholder="パスワード"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-12">
                <button className="btn btn-accent btn-wide">登録する</button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};
