import React from "react";
import { gtagEvent } from "../../../types/gtag";
import { FAQItem } from "../FAQPage";

export const CollapseItem: React.FC<FAQItem> = ({ id, question, answer }) => {
  return (
    <div
      tabIndex={0}
      className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box group"
      onClick={() => {
        gtagEvent(`click_faq_item_${id}`);
      }}
    >
      <div className="collapse-title text-xl font-medium  group-focus:bg-yellow-100">
        Q{`${id}`.padStart(2, "0")}. {question}
      </div>
      <div className="collapse-content group-focus:bg-yellow-100">
        <p>
          A{`${id}`.padStart(2, "0")}. {answer}
        </p>
      </div>
    </div>
  );
};
