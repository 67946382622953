import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";
import { Link } from "react-router-dom";
import { gtagEvent } from "../../../../types/gtag";

export const Campaign: React.FC = () => {
  return (
    <div
      className="hero py-12"
      style={{ backgroundImage: `url("https://placeimg.com/1000/800/arch")` }}
    >
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="w-full">
          <SectionTitle>
            <Link
              className="link link-warning"
              to="/gifts"
              onClick={() => {
                gtagEvent("click_gifts_page_link");
              }}
            >
              プレゼントキャンペーン中！
            </Link>
          </SectionTitle>
          <p className="mb-5">
            リモートワークに特化したITの備品をいまならプレゼント！
          </p>
          <div className="carousel rounded-box space-x-4">
            <div className="carousel-item">
              <img
                src="https://m.media-amazon.com/images/I/61FLOm0OiLL._AC_SL1500_.jpg"
                alt="Burger"
                className="w-64 rounded-box"
              />
            </div>
            <div className="carousel-item">
              <img
                src="https://m.media-amazon.com/images/I/71IO2vNcLrL._AC_SL1500_.jpg"
                alt="Burger"
                className="w-64 rounded-box"
              />
            </div>
            <div className="carousel-item">
              <img
                src="https://m.media-amazon.com/images/I/61KMkIm9bfL._AC_SL1500_.jpg"
                alt="Burger"
                className="w-64 rounded-box"
              />
            </div>
            <div className="carousel-item">
              <img
                src="https://m.media-amazon.com/images/I/71QaYacbfTL._AC_SL1500_.jpg"
                alt="Burger"
                className="w-64 rounded-box"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
