import React from "react";
import { FAQItem, QAndAPage } from "./FAQPage";

const QAndAPageContainer: React.VFC = () => {
  const qAndAItems: FAQItem[] = [
    {
      id: 1,
      question: "問い合わせや営業活動に料金はかかりますか？",
      answer:
        "面談、案件のご紹介には料金はかかりません。実際に案件を受注するまでは無料でフォローさせていただきます。",
    },
    {
      id: 2,
      question: "すぐに営業活動を始めてほしいのですが、可能ですか？",
      answer:
        "可能です。お問い合わせ頂いた◯営業日以内に弊社営業からご連絡致します。その後ヒヤリングさせていただき、早ければ当日からご希望に沿った案件を紹介させていただきます。",
    },
    {
      id: 3,
      question: "ブランクがあるのですが、案件はありますか？",
      answer:
        "ブランクの理由や期間にもよりますが、スキル感や稼働時間の調整ができる幅広い案件が揃っていますのでまずはヒヤリングさせていただければと思います。",
    },
    {
      id: 4,
      question:
        "子育て中で時間に制約(お迎えなど)がありますが、相談できますか？",
      answer:
        "可能です。フルタイム5日の案件だけでなく、週2~3日案件や時短案件等もございますのでまずは詳しい条件をお聞かせください。",
    },
    {
      id: 5,
      question: "経験が少ないのですが、案件はありますか？",
      answer:
        "弊社経由で未経験から案件参画して現在も活躍されている方もいらっしゃいます。ロースキルからハイスキルの幅広い案件が揃っておりますので安心してお問い合わせください。",
    },
    {
      id: 6,
      question: "問い合わせや営業活動に料金はかかりますか？",
      answer:
        "面談、案件のご紹介には料金はかかりません。実際に案件を受注するまでは無料でフォローさせていただきます。",
    },
    {
      id: 7,
      question: "紹介される案件の単価はどのくらいが相場ですか？",
      answer:
        "弊社で紹介される案件の単価は、案件の内容や難易度、エンジニアのスキルや経験によって異なります。弊社では、エンジニアが適正な単価で案件に参加できるよう、適切な単価交渉を支援しています。",
    },
    {
      id: 8,
      question: "エントリー後のヒヤリングは対面ですか？",
      answer:
        "対面でもオンラインでも対応可能です。オンラインの場合はzoomやGoogleMeat等のオンライン会議ツールを用いてヒヤリングさせていただければと思います。",
    },
    {
      id: 9,
      question: "案件が途切れることがないか不安です",
      answer:
        "弊社では案件が終了することが分かり次第、案件終了後すぐに次の案件に参画できるよう時期案件の営業を開始いたします。ご希望にあわせて最適な案件を紹介させていただきます",
    },
    {
      id: 10,
      question: "就業中ですが、エントリーしても大丈夫ですか？",
      answer:
        "大丈夫です。退職日予定日が決定していれば、時期に合わせて営業させていただきます。退職予定日が決定していない場合でもスキル感や経歴から予想単価等相談できますのでまずはお問い合わせいただき、状況をお聞かせいただければと思います。",
    },
    {
      id: 11,
      question: "地方の常駐案件はありますか？",
      answer:
        "弊社がご紹介する案件は基本的にはリモート案件がほとんどなのですが、中には常駐案件もございます。くわしい条件をお聞かせください。",
    },
    {
      id: 12,
      question: "紹介される案件の期間はどのくらいが多いですか？",
      answer:
        "弊社から紹介される案件の期間は、3ヶ月程度の短期のものから1年以上の長期のものまで、様々です。案件によって異なりますが、弊社ではエンジニアの都合に合わせて、短期案件や長期案件など、様々な選択肢を提供しています。",
    },
    {
      id: 13,
      question:
        "紹介される案件で、エンジニアのキャリアアップにつながることはありますか？",
      answer:
        "弊社から紹介される案件の中には、プロジェクトのリーダーやマネージャーなどのポジションに就くことができる場合もあります。また、弊社ではエンジニアとしてのキャリアアップに向けて、キャリアアドバイスや転職支援などのサポートも行っています。",
    },
  ];

  return <QAndAPage items={qAndAItems} />;
};

export default QAndAPageContainer;
