import React from "react";
import { Breadcrumb, useBreadcrumb } from "../../components/Breadcrumb";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

type HomePageProps = {
  allowDevelopUse: boolean;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
];

export const HomePage: React.FC<HomePageProps> = ({ allowDevelopUse }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  const itemStyle = [
    "bg-header/80",
    "rounded-2xl",
    "h-16",
    "w-72",
    "flex",
    "items-center",
    "justify-center",
    "text-white",
    "text-xl",
    "font-extrabold",
    "hover:bg-header",
    "active:bg-header",
  ].join(" ");
  return (
    <div className="container w-7/12 mx-auto">
      <h1 className="text-center text-5xl font-bold mb-10 opacity-50">
        RegionHack
      </h1>
      <div className="flex flex-row space-x-3 justify-around">
        {allowDevelopUse && (
          <div
            className={itemStyle}
            onClick={() => {
              history.push("/groups");
            }}
          >
            一斉配信
          </div>
        )}
        {allowDevelopUse && (
          <div
            className={itemStyle}
            onClick={() => {
              history.push("/companies");
            }}
          >
            パートナー管理
          </div>
        )}
        {allowDevelopUse && <div className={itemStyle}>エンジニア管理</div>}
        <div
          className={itemStyle}
          onClick={() => {
            history.push("/emails/search");
          }}
        >
          メール検索
        </div>
      </div>
    </div>
  );
};
