import React, { ButtonHTMLAttributes, useCallback } from "react";

export type ButtonUIProps = {
  size?: "lg" | "sm" | "xs";
  buttonType?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "link";
  isOutline?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonUI: React.FC<ButtonUIProps> = ({
  size = "",
  buttonType = "primary",
  isOutline = false,
  children,
  onClick,
  className,
}) => {
  const getClassName = useCallback(() => {
    switch (buttonType) {
      case "primary":
        return [
          "text-white btn rounded w-32",
          "btn-accent",
          size ? `btn-${size}` : "",
          isOutline ? "btn-outline" : "",
        ].join(" ");
      case "link":
        return ["link", "link-accent"].join(" ");
    }
  }, [size, isOutline, buttonType]);
  return (
    <button
      onClick={onClick}
      className={`${getClassName()} ${className ? className : ""}`}
    >
      {children}
    </button>
  );
};
