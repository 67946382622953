import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { AlertUI } from "../../../../components/Alert";
import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";
import { Gift } from "../gifts/GiftItem";
import DigitalNomad from "./digital_nomad.svg";
import Freee from "./freee.png";
import { Gifts } from "../gifts/Gifts";
import { gtagEvent } from "../../../../types/gtag";

type ToBeFreelancerGiftsSectionProps = HTMLAttributes<HTMLDivElement>;

export const ToBeFreelancerGiftsSection: React.FC<
  ToBeFreelancerGiftsSectionProps
> = ({ ...rest }) => {
  const toBeFreelancerGifts: Gift[] = [
    {
      id: 15,
      title: "会計freeeスタータープラン1年分の料金支援",
      description: `個人事業主として活動していくには経費の記帳や確定申告が必須になります。

      確定申告がサービス上で完結できたり、口座連携、クレジット連携により
      
      煩わしい事務作業を圧倒的に削減できるため、弊社では会計サービスとして会計freeeをオススメしています`,
      url: "https://www.freee.co.jp/accounting/individual/pricing/",
      src: Freee,
    },
    {
      id: 16,
      title: "サンワサプライ 接触型ICカードリーダライタ Windows/mac両対応",
      description:
        "会計freeeと併用することで、確定申告がfreeeサービス内で完結できる",
      url: "https://amzn.asia/d/20oqD6W",
      src: "https://m.media-amazon.com/images/I/71RYemF2JaL._AC_SL1280_.jpg",
    },
    {
      id: 17,
      title: "領収書ホルダー",
      description: `大容量と自立式のデザイン。収納力抜群！領収書ホルダー`,
      url: "https://amzn.asia/d/dulwlpY",
      src: "https://m.media-amazon.com/images/I/71ZAYEV+FUL._AC_SL1500_.jpg",
    },
  ];

  return (
    <div
      className="px-12 bg-base-100 flex flex-col items-center py-20"
      {...rest}
    >
      <SectionTitle className="underline decoration-blue-400 decoration-4">
        フリーランス転向プレゼント
      </SectionTitle>
      <img src={DigitalNomad} className="w-64 h-64" alt="tobe presents" />
      <AlertUI type="success" className="my-10 text-xl w-10/12">
        個人事業主に転向後、フリーランス初契約時に以下のサービスを弊社が負担します。
        <br />
        <Link
          className="link link-primary"
          to="/contact"
          onClick={() => {
            gtagEvent("click_try_contact_btn_in_tobe_freelancer_gifts_section");
          }}
        >
          →問い合わせる
        </Link>
      </AlertUI>
      <Gifts type="card" gifts={toBeFreelancerGifts} />
    </div>
  );
};
