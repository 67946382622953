import { createContext, useCallback } from "react";
import { Auth } from "aws-amplify";
import { SignUpStatus, useSignUpStatus } from "./useSignUpStatus";

export type SignInHandler = () => void;
export type SignOutHandler = () => void;
export type ClearSignUpStatusHandler = () => void;
export type SignUpHandler = (input: SignUpInput) => Promise<void>;

interface IAuthContext {
  signUpStatus: SignUpStatus;
  signIn: SignInHandler;
  signOut: SignOutHandler;
  signUp: SignUpHandler;
}

export type SignUpInput = {
  familyName: string;
  givenName: string;
  familyNameKana: string;
  givenNameKana: string;
  email: string;
  birthYear: string;
  birthMonth: string;
  birthDay: string;
  tel: string;
  job: string;
  prefecture: string;
  password: string;
};

// TODO: SignInリファクタ後削除
export enum ErrorCodes {
  userNameExistsException = "UsernameExistsException",
  invalidPasswordException = "InvalidPasswordException",
  invalidParameterException = "InvalidParameterException",
  codeMisMatchException = "CodeMismatchException",
  codeDeliveryFailureException = "CodeDeliveryFailureException",
  notAuthorizedException = "NotAuthorizedException",
}

type AuthError = {
  code: string;
  message: string;
};

const AuthErrors: AuthError[] = [
  {
    code: "UsernameExistsException",
    message: "入力されたメールアドレスは既に登録されています",
  },
  {
    code: "InvalidPasswordException",
    message: "パスワードが正しくありません",
  },
  {
    code: "InvalidParameterException",
    message: "有効でないパラメータが入力されています",
  },
  { code: "CodeMismatchException", message: "認証コードが正しくありません。" },
  {
    code: "CodeDeliveryFailureException",
    message: "認証コードの送信に失敗しました。",
  },
  { code: "NotAuthorizedException", message: "既に登録されています。" },
  {
    code: "UserLambdaValidationException",
    message:
      "システムエラーが発生しました。恐れいりますが管理者にお問いあわせください。",
  },
];

const AuthContext = createContext<IAuthContext>({
  signUpStatus: { email: "", confirming: false },
  signIn: () => {
    // nothing to do
  },
  signUp: async (_: SignUpInput) => {
    // nothing to do
  },
  signOut: () => {
    // nothing to do
  },
});

const getAuthErrorMessage = (code: string) => {
  const error = AuthErrors.find((error: AuthError) => error.code === code);
  return error ? error.message : "Unknown Error";
};

// TODO: 直す
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthProvider = (props: any) => {
  const [signUpStatus, setSignUpStatus] = useSignUpStatus();

  const onSignIn = useCallback(() => {
    // nothing to do
  }, []);

  const onSignOut = useCallback(() => {
    // nothing to do
  }, []);

  const onSignUp = useCallback(
    async (input: SignUpInput) => {
      try {
        await Auth.signUp({
          username: input.email,
          password: input.password,
          attributes: {
            family_name: input.familyName,
            given_name: input.givenName,
            "custom:family_name_kana": input.familyNameKana,
            "custom:given_name_kana": input.givenNameKana,
          },
        });
        setSignUpStatus({ email: input.email, confirming: true });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error.code);
        throw new Error(getAuthErrorMessage(error.code));
      }
    },
    [setSignUpStatus]
  );

  return (
    <AuthContext.Provider
      value={{
        signUpStatus: signUpStatus,
        signIn: onSignIn,
        signOut: onSignOut,
        signUp: onSignUp,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
