import { Card } from "../../../../components/Card/Card";

export type ProjectIntroductionItemProps = {
  projectName: string;
  price: string;
  tags: string[];
};

export const ProjectIntroductionItem: React.FC<
  ProjectIntroductionItemProps
> = ({ projectName, price, tags }) => {
  return (
    <Card title={projectName} className="pt-4 w-96">
      <div className="p-4">
        <p className="text-red-400 text-2xl mb-4">{`${price}円／月`}</p>
        <div className="card-actions justify-end">
          {tags.map((tag, index) => (
            <div key={`tag_${index}`} className="badge badge-outline">
              {tag}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};
