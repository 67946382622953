import React from "react";
import { Link } from "react-router-dom";
import { gtagEvent } from "../../../../types/gtag";

type SupportCardProps = {
  support: Support;
};

export type Support = {
  id: number;
  title: string;
  description: string;
  src: string;
};

export const SupportCard: React.FC<SupportCardProps> = ({ support }) => {
  return (
    <div className="card card-side bg-base-100 my-12 shadow-md">
      <img src={support.src} alt="" className="object-cover h-56 w-56" />
      <div className="card-body">
        <h2 className="card-title underline">{support.title}</h2>
        <p>{support.description}</p>
        <div className="card-actions justify-end">
          <Link
            className="btn btn-success rounded text-white"
            to="/contact"
            onClick={() => {
              gtagEvent(`click_support_item_${support.id}`);
            }}
          >
            詳しく聞いてみる
          </Link>
        </div>
      </div>
    </div>
  );
};
