import React, { HTMLAttributes } from "react";
import { Support, SupportCard } from "../SupportCard";
import Adjustment from "./adjustment.svg";
// import MindTea from "./mind_tea.svg";
type ForEngineersUnderContractTabContentProps = HTMLAttributes<HTMLDivElement>;

export const ForEngineersUnderContractTabContent: React.FC<
  ForEngineersUnderContractTabContentProps
> = ({ className }) => {
  const supports: Support[] = [
    {
      id: 9,
      title: "チューニングサービス",
      description:
        "実際に開発案件に参加すると、トラブルが発生したり、単価アップの交渉をしたいときもあるでしょう。あなたの専任スタッフが間に入り、トラブルの解消や単価交渉をサポートすることで、契約や人間関係のトラブルを未然に防ぐことができます。",
      src: Adjustment,
    },
    // これはファーストリリースには含めない。理由は心理カウンセラーと定形契約を結ぶなどバックオフィスの準備が必要なため。
    // {
    //   title: "セラピー支援",
    //   description: `フリーランスエンジニアとして業務に参画しているときに生じるストレスや不安に対する心理的支援を提供します。`,
    //   src: MindTea,
    // },
  ];
  return (
    <div className={`${className}`}>
      {supports.map((support: Support, index: number) => (
        <SupportCard key={index} support={support} />
      ))}
    </div>
  );
};
