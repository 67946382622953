import React, { useEffect } from "react";
import { TopSection } from "./components/TopSection";
import { OpportunityGiftsSection } from "./components/OpportunityGiftsSection";
import { ToBeFreelancerGiftsSection } from "./components/ToBeFreelancerGiftsSection";
import { RemoteSupportGiftsSection } from "./components/RemoteSupportGiftsSection";
import { animateScroll } from "react-scroll";

export const GiftsPage: React.FC = () => {
  useEffect(() => {
    animateScroll.scrollToTop({ duration: 0 });
  }, []);
  return (
    <div className="bg-white">
      {/* トップ */}
      <TopSection />
      {/* 無料相談プレゼント */}
      <OpportunityGiftsSection id="opportunity" />
      {/* リモート支援プレゼント */}
      <RemoteSupportGiftsSection id="remotesupport" />
      {/* フリーランス転向プレゼント */}
      <ToBeFreelancerGiftsSection id="tobefreelancer" />
    </div>
  );
};
