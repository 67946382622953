import React, {
  forwardRef,
  AnchorHTMLAttributes,
  DetailedHTMLProps,
} from "react";
import { Link, LinkProps } from "react-router-dom";
import { ReactComponent as GroupIcon } from "./group.svg";
import { ReactComponent as HistoriesIcon } from "./histories.svg";
import { ReactComponent as CompanyIcon } from "./company.svg";
import { ReactComponent as EmailSearchIcon } from "./email-search.svg";
import { ReactComponent as EmailIcon } from "./email.svg";
import { ReactComponent as UsersIcon } from "./users.svg";
import { ReactComponent as EmployeesIcon } from "./employees.svg";
import { ReactComponent as UploadIcon } from "./upload.svg";

interface SideBarMenuItemProps {
  id: string;
  title: string;
  selected: boolean;
  to: LinkProps["to"];
  onClickMenuItem: (open: boolean) => void;
}

type AnchorLinkProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate: any;
} & DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const AnchorLink = forwardRef<HTMLAnchorElement, AnchorLinkProps>(
  (props, ref) => {
    const { navigate, ...rest } = props;
    return (
      <a
        ref={ref}
        {...rest}
        onClick={e => {
          e.preventDefault();
          navigate();
          props.onClick && props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  }
);

const SideBarMenuItemUI: React.FC<SideBarMenuItemProps> = ({
  id,
  title,
  to,
  selected,
  onClickMenuItem,
}) => {
  return (
    <li key={id}>
      <Link
        to={to}
        className={`inline-flex items-center w-full px-4 py-2 mt-1 text-base text-white transition duration-500 ease-in-out transform ${
          selected ? "bg-pink-600" : ""
        } rounded-lg focus:shadow-outline white=70  hover:border-indigo-800 focus:shadow-outline hover:bg-indigo-600`}
        component={AnchorLink}
        onClick={() => onClickMenuItem(true)}
      >
        {id === "groups" && <GroupIcon className="w-8 h-8" />}
        {id === "emailNew" && <EmailIcon className="w-8 h-8" />}
        {id === "emailsHistories" && <HistoriesIcon className="w-8 h-8" />}
        {id === "engineers" && <UsersIcon className="w-8 h-8" />}
        {id === "companies" && <CompanyIcon className="w-8 h-8" />}
        {id === "employees" && <EmployeesIcon className="w-8 h-8" />}
        {id === "emailsSearch" && <EmailSearchIcon className="w-8 h-8" />}
        {id === "bulk" && <UploadIcon className="w-8 h-8" />}
        <span className="ml-4">{title}</span>
      </Link>
    </li>
  );
};
export default SideBarMenuItemUI;
