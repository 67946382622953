import { HTMLAttributes } from "react";

export type SectionTitleProps = HTMLAttributes<HTMLElement>;

export const SectionTitle: React.FC<SectionTitleProps> = ({className, children}) => {
  return (
    <>
      <div className={`text-center text-5xl mb-6 font-bold ${className}`}>{children}</div>
    </>
  )
}
