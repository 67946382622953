import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { AlertUI } from "../../../../components/Alert";
import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";
import { Gift } from "../gifts/GiftItem";
import RemoteWork from "./remote_work.svg";
import { Gifts } from "../gifts/Gifts";
import { gtagEvent } from "../../../../types/gtag";

type RemoteSupportGiftsSectionProps = HTMLAttributes<HTMLDivElement>;

export const RemoteSupportGiftsSection: React.FC<
  RemoteSupportGiftsSectionProps
> = ({ ...rest }) => {
  const remoteSupportGifts: Gift[] = [
    {
      id: 7,
      title: "ロジクール Webカメラ C920n",
      description: `ロジクールC920n Webカメラで、オンラインミーティングをより質の高いものに`,
      url: "https://amzn.asia/d/6CrIyRj",
      src: "https://m.media-amazon.com/images/I/61FLOm0OiLL._AC_SL1500_.jpg",
    },
    {
      id: 8,
      title: "ホットアイマスク",
      description: `グラフェン急速加熱＆向上された耐久性。安っぽい銅発熱配線より薄く、強靭で、電導性に優れた素材グラフェン加熱で眼疲れも軽減`,
      url: "https://amzn.asia/d/5yoKX4b",
      src: "https://m.media-amazon.com/images/I/71IO2vNcLrL._AC_SL1500_.jpg",
    },
    {
      id: 9,
      title: "USB C ハブ アダプタ",
      description: "USB-Cハブで、周辺機器を簡単に接続｜デュアルモニターに最適",
      url: "https://amzn.asia/d/3g6C5yy",
      src: "https://m.media-amazon.com/images/I/61KMkIm9bfL._AC_SL1500_.jpg",
    },
    {
      id: 10,
      title: "ネスカフェ ゴールドブレンド バリスタ コーヒーメーカー",
      description: `本格的なコーヒーを、いつでも手軽に。`,
      url: "https://amzn.asia/d/6shTWrG",
      src: "https://m.media-amazon.com/images/I/71QaYacbfTL._AC_SL1500_.jpg",
    },
    {
      id: 11,
      title: "アロマ機能付 加湿器 超音波式 オート運転機能",
      description: `家族の集まるリビングをパワフルに加湿。
      フタを開ければ上からカンタンたっぷり給水。広い部屋も適湿に、家族みんなでリラックス。`,
      url: "https://amzn.asia/d/hUByRKM",
      src: "https://m.media-amazon.com/images/I/51gM6+ug8jL._AC_SL1000_.jpg",
    },
    {
      id: 12,
      title: "オフィスレザーデスクマット",
      description: `長時間使用も疲れにくい`,
      url: "https://amzn.asia/d/5YylWfs",
      src: "https://m.media-amazon.com/images/I/71QupXiGUpL._AC_SL1500_.jpg",
    },
    {
      id: 13,
      title: "ロジクール ワイヤレスマウス トラックボール",
      description: `10年振りのアップデートとなるトラックボールマウス最新モデル`,
      url: "https://amzn.asia/d/7qJHXbX",
      src: "https://m.media-amazon.com/images/I/61rTlqFFzZL._AC_SL1500_.jpg",
    },
    {
      id: 14,
      title: "パソコンスタンド",
      description: `長時間のデスクワークでの肩や首などへの負担を軽減してくれて、猫背などを有効に防止できます。`,
      url: "https://amzn.asia/d/44nGGkb",
      src: "https://m.media-amazon.com/images/I/61qEhrlMjrL._AC_SL1500_.jpg",
    },
  ];

  return (
    <div
      className="px-12 bg-base-100 flex flex-col items-center py-20"
      {...rest}
    >
      <SectionTitle className="underline decoration-4 decoration-green-400">
        リモート支援プレゼント
      </SectionTitle>
      <img src={RemoteWork} className="w-64 h-64" alt="remote presents" />
      <AlertUI type="success" className="my-10 text-xl w-10/12">
        開発案件ご成約後に以下の賞品の中から一つをプレゼント
        <br />
        <Link
          className="link link-primary"
          to="/contact"
          onClick={() => {
            gtagEvent("click_try_contact_btn_in_remote_support_gifts_section");
          }}
        >
          →問い合わせる
        </Link>
      </AlertUI>
      <Gifts type="card" gifts={remoteSupportGifts} />
    </div>
  );
};
