import React from "react";
import { gtagEvent } from "../../../../types/gtag";

export type Gift = {
  id: number;
  title: string;
  description: string;
  url: string;
  src: string;
};

type BaseItemProps = {
  gift: Gift;
};

type GiftItemProps = {
  type: "card" | "book";
} & BaseItemProps;

export const GiftItem: React.FC<GiftItemProps> = ({ gift, type }) => {
  switch (type) {
    case "card":
      return <CardItem gift={gift} />;
    case "book":
      return <BookItem gift={gift} />;
  }
};

const CardItem: React.FC<BaseItemProps> = ({ gift }) => {
  return (
    <div className="card card-compact lg:w-84 xl:w-92 2xl:w-96 bg-base-100 shadow-xl">
      <img src={gift.src} alt="" className="object-top object-cover h-60" />
      <div className="card-body">
        <h3 className="card-title">{gift.title}</h3>
        <p className="text-xs text-slate-500">{gift.description}</p>
        <div className="card-actions justify-end">
          <a
            className="btn btn-outline btn-accent"
            href={gift.url}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              gtagEvent(`click_gift_item_${gift.id}`);
            }}
          >
            詳細ページへ
          </a>
        </div>
      </div>
    </div>
  );
};

const BookItem: React.FC<BaseItemProps> = ({ gift }) => {
  return (
    <div className="card card-side bg-base-100 shadow-xl">
      <img
        src={gift.src}
        alt="book"
        className="w-44 object-top object-contain"
      />
      <div className="card-body">
        <h3 className="card-title">{gift.title}</h3>
        <p className="text-xs text-slate-500">{gift.description}</p>
        <div className="card-actions justify-end">
          <a
            className="btn btn-outline btn-accent"
            href={gift.url}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              gtagEvent(`click_gift_item_${gift.id}`);
            }}
          >
            詳細ページへ
          </a>
        </div>
      </div>
    </div>
  );
};
