import React from "react";
import { HTMLAttributes } from "react-router/node_modules/@types/react";

export type PageTitleUIProps = HTMLAttributes<HTMLElement>;

export const PageTitleUI: React.FC<PageTitleUIProps> = ({
  children,
  className,
}) => {
  return (
    <h1 className={`text-center text-3xl font-bold ${className}`}>
      {children}
    </h1>
  );
};
