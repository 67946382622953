import { HTMLAttributes } from "react";

type Props = {
  imgPath: string;
  title: string
  texts: string[];
} & HTMLAttributes<HTMLDivElement>

export const SellingPointItemRight: React.FC<Props> = props => {
  const { ...rest } = props;
  return (
    <div className="hero bg-base-100" { ...rest }>
      <div className="hero-content flex-col lg:flex-row">
        <img src={props.imgPath} alt="Shoes" className="rounded-lg w-1/2" />
        <div>
          <h1 className="text-4xl font-bold">{props.title}</h1>
          {props.texts.map((text, index) => <p key={`p_${index}`} className="py-6 leading-9">{text}</p>)}
        </div>
      </div>
    </div>
  )
}

export const SellingPointItemLeft: React.FC<Props> = props => {
  const { ...rest } = props;
  return (
    <div className="hero bg-base-100" { ...rest }>
      <div className="hero-content flex-col lg:flex-row-reverse">
        <img src={props.imgPath} alt="Shoes" className="rounded-lg w-1/2" />
        <div>
          <h1 className="text-4xl font-bold">{props.title}</h1>
          {props.texts.map((text, index) => <p key={`p_${index}`} className="py-6 leading-9">{text}</p>)}
        </div>
      </div>
    </div>
  )
}