import React, { InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

export type InputUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputUI: React.FC<InputUIProps> = ({
  className = "",
  registerName,
  children,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[registerName];
  return (
    <div className={`flex flex-col h-16 ${className}`}>
      <input
        className={`input input-bordered ${
          error ? "input-error" : "input-accent"
        } bg-white`}
        {...register(registerName)}
        {...rest}
      />
      {error && <span className="text-error text-xs">{error.message}</span>}
    </div>
  );
};
