import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Redirect,
  useHistory,
} from "react-router-dom";
import { initializeGA } from "./lib/googleAnalytics";
// import AboutPage from "./routes/AboutPage";
import HomePage from "./routes/HomePage";
import LandingPage from "./routes/LandingPage";
// import TodosPage from "./routes/todos/TodosPage";
// import TodoDetailPage from "./routes/todos/TodoDetailPage";
// import EmployeesPage from "./routes/employees/EmployeesPage";
// import EmployeeNewPage from "./routes/employees/EmployeeNewPage";
// import EmployeeNewConfirmPage from "./routes/employees/EmployeeNewConfirmPage";
// import CompaniesPage from "./routes/companies/CompaniesPage";
// import CompanyNewPage from "./routes/companies/CompanyNewPage";
// import CompanyNewConfirmPage from "./routes/companies/CompanyNewConfirmPage";
// import DeliveryGroupsPage from "./routes/deliveryGroups/DeliveryGroupsPage";
// import DeliveryGroupNewPage from "./routes/deliveryGroups/DeliveryGroupNewPage";
// import DeliveryEmailNewPage from "./routes/emails/DeliveryEmailNewPage";
// import DeliveryGroupNewConfirmPage from "./routes/deliveryGroups/DeliveryGroupNewConfirmPage";
// import DeliveryEmailNewConfirmPage from "./routes/emails/DeliveryEmailNewConfirmPage";
// import DeliveryHistoriesPage from "./routes/deliveryHistories/DeliveryHistoriesPage";
// import TodoNewPage from "./routes/todos/TodoNewPage";
import { useIsUserLoggedInQuery } from "./generated/graphql";
import Layout from "./components/Layout";
import { Hub } from "aws-amplify";
import { HubCapsule } from "@aws-amplify/core";
import SignUpPage from "./routes/SignUpPage";
// import SignInPage from "./routes/SignInPage";
// import RedirectPage from "./routes/RedirectPage";
// import ProfilePage from "./routes/ProfilePage";
// import MatterPage from "./routes/MatterPage/MatterPage";
// import MailEditPage from "./routes/deliveryGroups/MailEditPage";
// import SearchEmailsPage from "./routes/emails/SearchEmailsPage";
// import BulkRegistrationPage from "./routes/settings/BulkRegistrationPage";
import "react-toastify/dist/ReactToastify.css";
import { GiftsPage } from "./routes/GiftsPage/GiftsPage";
import SupportsPage from "./routes/SupportsPage";
import ContactPage from "./routes/ContactPage";
import { PublicLayout } from "./components/PublicLayout";
import QAndAPage from "./routes/FAQPage";
import SellingPointPage from "./routes/SellingPointPage";

/**
 * @return {Router} ルーター
 */
function App() {
  useEffect(() => {
    initializeGA();
  }, []);
  return (
    <Router>
      <Switch>
        <Route path="/signup">
          <SignUpPage />
        </Route>
        <Route path="/gifts">
          <PublicLayout>
            <GiftsPage />
          </PublicLayout>
        </Route>
        <Route path="/supports">
          <PublicLayout>
            <SupportsPage />
          </PublicLayout>
        </Route>
        <Route path="/contact">
          <PublicLayout>
            <ContactPage />
          </PublicLayout>
        </Route>
        <Route path="/selling_point">
          <PublicLayout>
            <SellingPointPage />
          </PublicLayout>
        </Route>
        <Route path="/questions">
          <PublicLayout>
            <QAndAPage />
          </PublicLayout>
        </Route>
        {/* <Route path="/login">
          <SignInPage />
        </Route> */}
        <Route path="/">
          <PublicLayout>
            <LandingPage />
          </PublicLayout>
        </Route>
        <AuthRedirect>
          <Layout>
            <Switch>
              {/* <Route path="/redirect">
                <RedirectPage />
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
              <PrivateRoute path="/todos/new">
                <TodoNewPage />
              </PrivateRoute>
              <PrivateRoute path="/todos/:id">
                <TodoDetailPage />
              </PrivateRoute>
              <PrivateRoute path="/todos">
                <TodosPage />
              </PrivateRoute>
              <PrivateRoute path="/profile">
                <ProfilePage />
              </PrivateRoute>
              <PrivateRoute path="/matter">
                <MatterPage />
              </PrivateRoute>
              <PrivateRoute path="/groups/:id/mail/edit">
                <MailEditPage />
              </PrivateRoute>
              <PrivateRoute path="/emails/search">
                <SearchEmailsPage />
              </PrivateRoute>
              <PrivateRoute path="/employees">
                <EmployeesPage />
              </PrivateRoute>
              <PrivateRoute path="/employee/new/confirm">
                <EmployeeNewConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/employee/new">
                <EmployeeNewPage />
              </PrivateRoute>
              <PrivateRoute path="/companies">
                <CompaniesPage />
              </PrivateRoute>
              <PrivateRoute path="/company/new/confirm">
                <CompanyNewConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/company/new">
                <CompanyNewPage />
              </PrivateRoute>
              <PrivateRoute path="/groups">
                <DeliveryGroupsPage />
              </PrivateRoute>
              <PrivateRoute path="/group/new/confirm">
                <DeliveryGroupNewConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/group/new">
                <DeliveryGroupNewPage />
              </PrivateRoute>
              <PrivateRoute path="/email/new/confirm">
                <DeliveryEmailNewConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/emails/histories">
                <DeliveryHistoriesPage />
              </PrivateRoute>
              <PrivateRoute path="/email/new">
                <DeliveryEmailNewPage />
              </PrivateRoute>
              <PrivateRoute path="/settings/bulk">
                <BulkRegistrationPage />
              </PrivateRoute> */}
              {/* <PrivateRoute path="/users">
                <UsersPage />
              </PrivateRoute> */}
              <PrivateRoute path="/home">
                <HomePage />
              </PrivateRoute>
            </Switch>
          </Layout>
        </AuthRedirect>
      </Switch>
    </Router>
  );
}

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { data, error, loading } = useIsUserLoggedInQuery();
  if (error) {
    console.error(error);
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        data.isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const AuthRedirect: React.FC<RouteProps> = ({ children }) => {
  const history = useHistory();

  const redirectToPreviousPageAfterLogin = (data: HubCapsule) => {
    const { payload } = data;
    switch (payload.event) {
      case "customOAuthState":
        history.push(decodeURIComponent(payload.data));
        break;
      case "signIn_failure":
        history.push("/");
        break;
    }
  };
  useEffect(() => {
    Hub.listen("auth", redirectToPreviousPageAfterLogin);
    return () => {
      Hub.remove("auth", redirectToPreviousPageAfterLogin);
    };
  }, []); // eslint-disable-line
  return <>{children}</>;
};

export default App;
