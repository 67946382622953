import React from "react";
import { HTMLAttributes } from "react-router/node_modules/@types/react";

export type FormGroupLabelUIProps = {
  required?: boolean;
} & HTMLAttributes<HTMLElement>;

export const FormGroupLabelUI: React.FC<FormGroupLabelUIProps> = ({
  children,
  className,
  required = false,
}) => {
  return (
    <span className={`flex text-sm font-semibold ${className}`}>
      {children}
      {required && (
        <div className="ml-1.5 badge border-red-400 text-red-400 badge-outline">必須</div>
      )}
    </span>
  );
};
