import { useHistory } from "react-router-dom";
import { gtagEvent } from "../../types/gtag";
import { CollapseItem } from "./components/Collapse";
import questionImg from "./question.svg";

export type FAQItem = {
  id: number;
  question: string;
  answer: string;
};
export type FAQPageProps = {
  items: FAQItem[];
};

export const QAndAPage = ({ items }: FAQPageProps) => {
  const history = useHistory();
  return (
    <div className="px-16 flex flex-col items-center">
      <img className="w-72 h-72" src={questionImg} alt="question" />
      <div className="text-4xl">よくあるご質問</div>
      <div
        className="text-xl text-yellow-400 right-0 underline mt-6"
        onClick={() => {
          gtagEvent("click_try_contact_btn_in_faq");
          history.push("contact");
        }}
      >
        もっと詳しく聞いてみる
      </div>
      <div className="py-16 w-[800px]">
        {items.map((item, index) => (
          <CollapseItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};
