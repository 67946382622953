import "yup-phone";
import ImgGeniuses from "./asset/geniuses.svg";
import ImgSaving from "./asset/savings.svg";
import ImgHeavenly from "./asset/heavenly.svg";
import ImgFamily from "./asset/family.svg";
import imgWorkingFromAnywere from "./asset/working_from_anywhere.svg";
import { useEffect } from "react";
import { SectionTitle } from "../../components/SectionTitle/SectionTItle";
import {
  SellingPointItemLeft,
  SellingPointItemRight,
} from "./components/SellingPointItem";

export enum SellingPointType {
  EfficientAssetBuilding = "EfficientAssetBuilding",
  SereneLife = "SereneLife",
  TimeWithLoved = "TimeWithLoved",
  Workcation = "Workcation",
}

type SellingPointPageProps = {
  initSellingPointType: SellingPointType | undefined;
};

export const SellingPointPage: React.FC<SellingPointPageProps> = ({
  initSellingPointType,
}) => {
  const items = [
    {
      imgPath: ImgSaving,
      title: "効率的な資産形成",
      texts: [
        `リモートワークにより地方に住んでいても都心部の案件に参画できるため、高い収入を維持しつつ生活することができます。地方での生活費は都会に比べて低く、不動産価格も都会に比べて抑えられています。日々の支出を抑えることにより、投資など資産形成しやすい環境を整えられます。`,
      ],
      id: SellingPointType.EfficientAssetBuilding,
    },
    {
      imgPath: ImgHeavenly,
      title: "自然に囲まれた落ち着いた暮らし",
      texts: [
        `地方での暮らしは日々の仕事やストレスから解放され、自然に囲まれた落ち着いた暮らしを送りたい方におすすめです。
        豊かな自然に囲まれた生活は、季節ごとに異なる景色を楽しめるだけでなく、心身ともにリフレッシュすることができます。朝起きて、窓を開ければ目の前には山々が広がり、鳥のさえずりや木々のざわめきが聞こえます。夜は星空を見ながら、風の音を聞きながらゆっくりと眠ることができます。
        また、自然に囲まれた地域では、豊富な野菜や果物、新鮮な魚介類など、美味しい食材が手に入ります。採れたての野菜や果物は栄養豊富で、料理が一層美味しくなります。地元の特産品やお祭りなど、地域の人々との交流も楽しむことができれば都心で暮らすよりも人の温かみに触れられる穏やかな暮らしを実現することができます。
        `,
      ],
      id: SellingPointType.SereneLife,
    },
    {
      imgPath: ImgFamily,
      title: "大切な人との時間",
      texts: [
        `リモートワークによって場所を選ばすに働くことができ、通勤時間や出張の必要性がなくなるので自由な時間を増やすことができます。
        地方に住み、家族や友人との時間を充実させることで、心身ともにリフレッシュできる機会を増やすこともできます。
        また地方には、季節の行事やイベントも豊富にあり、地元の人たちと交流を深めることもできます。こうした人との交流を通じて、新しい発見や気づきが生まれることもあります。
        `,
      ],
      id: SellingPointType.TimeWithLoved,
    },
    {
      imgPath: imgWorkingFromAnywere,
      title: "ワーケーション",
      texts: [
        `「ワーケーション」とは、「ワーク（仕事）」と「バケーション（休暇）」を組み合わせた言葉で、仕事をしながらバケーションを楽しむスタイルのことを指します。
        リモートワークとワーケーションは、互いに相乗効果を発揮できる関係性にあり、リモートワークは、場所や時間に縛られない柔軟な働き方を可能にし、
        ワーケーションは、仕事と休暇を組み合わせることで、リフレッシュし、新しいアイデアを得ることができる働き方です。
        趣味や観光がしやすい土地でリモートワークをすることで仕事とプライベートを両立しやすくし、柔軟性とリフレッシュ効果を兼ね備えた環境で仕事をすることは、モチベーションを高めたり、生産性を上げることができます。
        `,
      ],
      id: SellingPointType.Workcation,
    },
  ];

  useEffect(() => {
    if (!initSellingPointType) {
      return;
    }
    const targetEl = document.getElementById(initSellingPointType);
    targetEl?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [initSellingPointType]);

  return (
    <div className="px-12 bg-base-100 pt-6 flex flex-col items-center pb-12">
      <img className="w-48 h-48" src={ImgGeniuses} alt="案件紹介" />
      <SectionTitle>地方ワークの魅力</SectionTitle>
      <div className="flex flex-wrap justify-between w-full gap-8">
        {items.map((item, index) =>
          index % 2 === 0 ? (
            <SellingPointItemRight
              key={`right_item_${index}`}
              imgPath={item.imgPath}
              title={item.title}
              texts={item.texts}
              id={item.id}
            />
          ) : (
            <SellingPointItemLeft
              key={`left_item_${index}`}
              imgPath={item.imgPath}
              title={item.title}
              texts={item.texts}
              id={item.id}
            />
          )
        )}
      </div>
    </div>
  );
};
