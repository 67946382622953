import React, { ReactNode, useEffect, useState } from "react";
import { ForEngineersUnderContractTabContent } from "./components/ForEngineersUnderContractTabContent";
import { ForTobeFreelancersTabContent } from "./components/ForTobeFreelancersTabContent";
import { ForToGetUrbanProjectsTabContent } from "./components/ForToGetUrbanProjectsTabContent";
import { ForToGoCountrysideTabContent } from "./components/ForToGoCountrysideTabContent";
import { TopSection } from "./components/TopSection";
import { animateScroll } from "react-scroll";
import { camelToSnake } from "../../utils/textUtils";
import { gtagEvent } from "../../types/gtag";

export enum SupportType {
  ForToBeFreelancers = "ForToBeFreelancers",
  ForToGoCountryside = "ForToGoCountryside",
  ForToGetUrbanProjects = "ForToGetUrbanProjects",
  ForEngineersUnderContract = "ForEngineersUnderContract",
}

type TabPage = {
  tabTitle: string;
  tabContent: ReactNode;
  tabType: SupportType;
};

type SupoortsPageProps = {
  initSupportType: SupportType | undefined;
};

export const SupportsPage: React.FC<SupoortsPageProps> = ({
  initSupportType,
}) => {
  const [tabStatus, setTabStatus] = useState<SupportType>(
    initSupportType ? initSupportType : SupportType.ForToBeFreelancers
  );

  useEffect(() => {
    animateScroll.scrollToTop({ duration: 0 });
  }, []);

  const tabPageList: TabPage[] = [
    {
      tabTitle: "フリーランスを検討される方へ",
      tabContent: <ForTobeFreelancersTabContent className="py-16" />,
      tabType: SupportType.ForToBeFreelancers,
    },
    {
      tabTitle: "地方への転居を検討される方へ",
      tabContent: <ForToGoCountrysideTabContent className="py-16" />,
      tabType: SupportType.ForToGoCountryside,
    },
    {
      tabTitle: "地方在住で都心案件を検討される方へ",
      tabContent: <ForToGetUrbanProjectsTabContent className="py-16" />,
      tabType: SupportType.ForToGetUrbanProjects,
    },
    {
      tabTitle: "弊社の案件に参画されている方へ",
      tabContent: <ForEngineersUnderContractTabContent className="py-16" />,
      tabType: SupportType.ForEngineersUnderContract,
    },
  ];
  return (
    <div className="bg-white py-12">
      <TopSection />
      <div className="mx-auto container">
        <div className="tabs w-full">
          {tabPageList.map((tabPage: TabPage, index: number) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={index}
              className={`tab tab-lg tab-lifted ${
                tabStatus === tabPage.tabType ? "tab-active" : ""
              }`}
              onClick={() => {
                gtagEvent(`click_${camelToSnake(tabPage.tabType)}_tab`);
                setTabStatus(tabPage.tabType);
              }}
            >
              {tabPage.tabTitle}
            </a>
          ))}
        </div>
        {
          tabPageList.find((tabPage: TabPage) => tabStatus === tabPage.tabType)
            ?.tabContent
        }
      </div>
    </div>
  );
};
