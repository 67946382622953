import React from "react";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import HeaderLogo from "./logo.png";
import FAQButton from "./FAQButton/FAQButton";
import { gtagEvent } from "../../types/gtag";

type HeaderNaviItem = {
  label: string;
  to: string;
};

export const PublicHeader: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <div className="navbar bg-white h-24 shadow-md fixed z-50">
      <div className="navbar-start">
        <RouterLink
          className="ml-5"
          to="/"
          onClick={() => {
            gtagEvent("click_site_icon");
          }}
        >
          <img src={HeaderLogo} className="w-full h-8" alt="catch-copy" />
        </RouterLink>
      </div>
      <div className="navbar-center hidden lg:flex">
        {pathname === "/" && (
          <ul className="flex w-full space-x-8 px-1 text-lg font-black">
            {[
              { label: "案件の紹介", to: "introduction" },
              { label: "利用した方の声", to: "user-voice" },
              { label: "安心サポート", to: "supports" },
              { label: "安定の３つの理由", to: "reasons" },
            ].map((item: HeaderNaviItem, index: number) => (
              <li key={index}>
                <Link
                  to={item.to}
                  onClick={() => {
                    gtagEvent(`click_${item.to.replace(/-/g, "_")}_link`);
                  }}
                  smooth={true}
                  className="no-underline hover:underline hover:decoration-2 hover:underline-offset-4"
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="navbar-end mr-5">
        <FAQButton />
        {/* <button className="btn btn-primary">かんたん無料登録</button> */}
      </div>
    </div>
  );
};
