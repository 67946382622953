import React, { HTMLAttributes } from "react";
import { Support, SupportCard } from "../SupportCard";
import LocationReview from "./location_review.svg";

type ForToGoCountrysideTabContentProps = HTMLAttributes<HTMLDivElement>;

export const ForToGoCountrysideTabContent: React.FC<
  ForToGoCountrysideTabContentProps
> = ({ className }) => {
  const supports: Support[] = [
    {
      id: 6,
      title: "地方移住を助成する情報のアナウンス",
      description:
        "国や地方自治体は、地方に移住する方を積極的に支援していることも少なくありません。あなたが移住されたい場所で活用できる助成金制度がございましたら提案いたします。またお得な制度を踏まえて居住地を決定されたい場合も相談に乗らせていただきます。",
      src: LocationReview,
    },
    // TODO: 以下は別途実装が必要なためFirst リリースには含めない
    // {
    //   title: "ローカルエリアプラットフォーム",
    //   description: `地方から東京の案件に参加するフリーランスエンジニア同士がチャットやビデオ通話を通じてコミュニケーションを取り合えるプラットフォームを提供することで、コミュニケーションのサポートを行います。`,
    //   src: LocationReview,
    // },
  ];
  return (
    <div className={`${className}`}>
      {supports.map((support: Support, index: number) => (
        <SupportCard key={index} support={support} />
      ))}
    </div>
  );
};
