import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  URL: string;
  JSON: {[key: string]: any};
  Email: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



export type Mutation = {
  __typename?: 'Mutation';
  /** 会社と従業員一括登録 */
  bulkCreateCompaniesWithEmployees: Scalars['Boolean'];
  /** 会社と従業員一括削除 */
  bulkAllDeleteCompanies: Scalars['Boolean'];
  /** 会社作成 */
  createCompany: Company;
  /** 会社更新 */
  updateCompany: Company;
  /** 会社削除 */
  deleteCompany: Scalars['Boolean'];
  /** メール配信 */
  deliveryEmail: Scalars['Boolean'];
  /** 配信グループ作成 */
  createDeliveryGroup: DeliveryGroup;
  /** 配信グループ更新 */
  updateDeliveryGroup: DeliveryGroup;
  /** 配信グループ削除 */
  deleteDeliveryGroup: Scalars['Boolean'];
  /** Email登録 */
  registerEmailDocument: OsCreatedResult;
  /** Email削除 */
  deleteEmailDocument: OsDeletedResult;
  /** 従業員作成 */
  createEmployee: Employee;
  /** 従業員更新 */
  updateEmployee: Employee;
  /** 従業員削除 */
  deleteEmployee: Scalars['Boolean'];
  /** ライセンス作成 */
  createLicense: License;
  /** Email一斉送信 */
  broadcastMatter: Array<Scalars['JSON']>;
  /** 案件作成 */
  createMatter: Matter;
  /** OpenSearchの生のqueryを実行する */
  execRawQuery?: Maybe<Scalars['JSON']>;
  /** indexのSettingsの設定 */
  createEmailIndex?: Maybe<Scalars['JSON']>;
  /** ロール作成 */
  createRole: Role;
  /** Todo作成 */
  createTodo?: Maybe<Todo>;
  /** ユーザー更新 */
  updateUser: User;
};


export type MutationBulkCreateCompaniesWithEmployeesArgs = {
  inputs: Array<CreateCompanyWithEmployeeInput>;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeliveryEmailArgs = {
  input: DeliveryEmailInput;
};


export type MutationCreateDeliveryGroupArgs = {
  input: CreateDeliveryGroupInput;
};


export type MutationUpdateDeliveryGroupArgs = {
  input: UpdateDeliveryGroupInput;
};


export type MutationDeleteDeliveryGroupArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterEmailDocumentArgs = {
  input?: Maybe<EmailDocumentInput>;
};


export type MutationDeleteEmailDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLicenseArgs = {
  input: CreateLicenseInput;
};


export type MutationBroadcastMatterArgs = {
  input?: Maybe<BroadcastMatterInput>;
};


export type MutationCreateMatterArgs = {
  input: MatterInput;
};


export type MutationExecRawQueryArgs = {
  input?: Maybe<ExecRawQueryInput>;
};


export type MutationCreateEmailIndexArgs = {
  indexName?: Maybe<Scalars['String']>;
};


export type MutationCreateRoleArgs = {
  input: RoleInput;
};


export type MutationCreateTodoArgs = {
  input: TodoInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** 会社と従業員登録 */
export type CreateCompanyWithEmployeeInput = {
  /** 会社名 */
  name: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 繋いだ人のID */
  connecterId: Scalars['ID'];
  /** 従業員の名前 */
  employeeName: Scalars['String'];
  /** 従業員のメールアドレス */
  employeeEmail: Scalars['Email'];
  /** 登録日 */
  createdAt: Scalars['DateTime'];
  /** 有効/無効 */
  disabled: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  /** indexのSettings取得 */
  checkIndexAnalyze?: Maybe<Scalars['JSON']>;
  /** 会社一覧取得 */
  companies: Companies;
  /** 会社取得 */
  company: Company;
  /** 配信グループ取得 */
  deliveryGroup: DeliveryGroup;
  /** 配信グループ一覧取得 */
  deliveryGroups: DeliveryGroups;
  /** 配信履歴一覧取得 */
  deliveryHistories: DeliveryHistories;
  /** 配信履歴取得 */
  deliveryHistory: DeliveryHistory;
  /** Email取得 */
  email: EmailDocument;
  /** Email一覧取得 */
  emails: EmailDocuments;
  /** 従業員取得 */
  employee: Employee;
  /** 従業員一覧取得 */
  employees: Employees;
  /** hello */
  hello: Scalars['String'];
  /** indexのSettings取得 */
  indexSettings?: Maybe<Scalars['JSON']>;
  isLoggedIn?: Maybe<Scalars['Boolean']>;
  /** ライセンス一覧取得 */
  licenses: Licenses;
  /** カレントユーザー取得 */
  me: User;
  /** ロール一覧取得 */
  roles?: Maybe<Roles>;
  /** todo取得 */
  todo?: Maybe<Todo>;
  /** todo一覧 */
  todos?: Maybe<Array<Todo>>;
  /** ユーザー取得 */
  user: User;
  /** ユーザー一覧取得 */
  users: Users;
};


export type QueryCheckIndexAnalyzeArgs = {
  index?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['JSON']>;
};


export type QueryCompaniesArgs = {
  filter?: Maybe<CompanySearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryDeliveryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryDeliveryGroupsArgs = {
  filter?: Maybe<DeliveryGroupSearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryHistoriesArgs = {
  filter?: Maybe<DeliveryHistorySearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryEmailArgs = {
  id: Scalars['ID'];
};


export type QueryEmailsArgs = {
  filter?: Maybe<EmailSearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeesArgs = {
  filter?: Maybe<EmployeeSearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryIndexSettingsArgs = {
  index?: Maybe<Scalars['String']>;
};


export type QueryTodoArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: Maybe<UserSearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** 会社一覧 */
export type Companies = {
  __typename?: 'Companies';
  totalCount: Scalars['Int'];
  nodes: Array<Company>;
};

/** 会社検索 */
export type CompanySearchInput = {
  name?: Maybe<Scalars['String']>;
};

/** 会社作成 */
export type CreateCompanyInput = {
  /** 会社名 */
  name: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 設立年 */
  yearOfEstablishment?: Maybe<Scalars['Int']>;
  /** 資本金 */
  capital?: Maybe<Scalars['Int']>;
  /** 従業員の数 */
  numberOfEmployees?: Maybe<Scalars['Int']>;
  /** エンジニアの数 */
  numberOfEngineers?: Maybe<Scalars['Int']>;
  /** フリーランスエンジニアの数 */
  numberOfFreelancers?: Maybe<Scalars['Int']>;
  /** ビジネスパートナーの数 */
  numberOfBusinessPartners?: Maybe<Scalars['Int']>;
  /** ビジネスパートナーのエンジニアの数 */
  numberOfBPEngineers?: Maybe<Scalars['Int']>;
  /** 営業の数 */
  numberOfSales?: Maybe<Scalars['Int']>;
  /** 配信用email */
  deliveryEmail?: Maybe<Scalars['Email']>;
  /** 郵便番号 */
  zip?: Maybe<Scalars['String']>;
  /** 都道府県 */
  prefecture?: Maybe<Scalars['String']>;
  /** 住所1 */
  address1?: Maybe<Scalars['String']>;
  /** 住所2 */
  address2?: Maybe<Scalars['String']>;
  /** 繋いだ人のID */
  connecterId: Scalars['ID'];
  /** 有効/無効 */
  disabled: Scalars['Boolean'];
  /** 無効な理由 */
  disabledReason?: Maybe<Scalars['String']>;
  /** 作成日 */
  createdAt?: Maybe<Scalars['DateTime']>;
};

/** 会社更新 */
export type UpdateCompanyInput = {
  /** 会社ID */
  id: Scalars['ID'];
  /** 会社名 */
  name: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 設立年 */
  yearOfEstablishment?: Maybe<Scalars['Int']>;
  /** 資本金 */
  capital?: Maybe<Scalars['Int']>;
  /** 従業員の数 */
  numberOfEmployees?: Maybe<Scalars['Int']>;
  /** エンジニアの数 */
  numberOfEngineers?: Maybe<Scalars['Int']>;
  /** フリーランスエンジニアの数 */
  numberOfFreelancers?: Maybe<Scalars['Int']>;
  /** ビジネスパートナーの数 */
  numberOfBusinessPartners?: Maybe<Scalars['Int']>;
  /** ビジネスパートナーのエンジニアの数 */
  numberOfBPEngineers?: Maybe<Scalars['Int']>;
  /** 営業の数 */
  numberOfSales?: Maybe<Scalars['Int']>;
  /** 配信用email */
  deliveryEmail?: Maybe<Scalars['Email']>;
  /** 郵便番号 */
  zip?: Maybe<Scalars['String']>;
  /** 都道府県 */
  prefecture?: Maybe<Scalars['String']>;
  /** 住所1 */
  address1?: Maybe<Scalars['String']>;
  /** 住所2 */
  address2?: Maybe<Scalars['String']>;
  /** 繋いだ人のID */
  connecterId: Scalars['ID'];
  /** 有効/無効 */
  disabled: Scalars['Boolean'];
  /** 無効な理由 */
  disabledReason?: Maybe<Scalars['String']>;
};

/** 会社 */
export type Company = {
  __typename?: 'Company';
  /** 会社ID */
  id: Scalars['ID'];
  /** 会社名 */
  name: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 設立年 */
  yearOfEstablishment?: Maybe<Scalars['Int']>;
  /** 資本金 */
  capital?: Maybe<Scalars['Int']>;
  /** 従業員の数 */
  numberOfEmployees?: Maybe<Scalars['Int']>;
  /** エンジニアの数 */
  numberOfEngineers?: Maybe<Scalars['Int']>;
  /** フリーランスエンジニアの数 */
  numberOfFreelancers?: Maybe<Scalars['Int']>;
  /** ビジネスパートナーの数 */
  numberOfBusinessPartners?: Maybe<Scalars['Int']>;
  /** ビジネスパートナーのエンジニアの数 */
  numberOfBPEngineers?: Maybe<Scalars['Int']>;
  /** 営業の数 */
  numberOfSales?: Maybe<Scalars['Int']>;
  /** 配信用email */
  deliveryEmail?: Maybe<Scalars['Email']>;
  /** 郵便番号 */
  zip?: Maybe<Scalars['String']>;
  /** 都道府県 */
  prefecture?: Maybe<Scalars['String']>;
  /** 住所1 */
  address1?: Maybe<Scalars['String']>;
  /** 住所2 */
  address2?: Maybe<Scalars['String']>;
  /** 繋いだ人のID */
  connecterId: Scalars['ID'];
  /** 繋いだ人 */
  connecter: User;
  /** 有効/無効 */
  disabled: Scalars['Boolean'];
  /** 無効な理由 */
  disabledReason?: Maybe<Scalars['String']>;
};

/** 入力アドレス */
export type Address = {
  __typename?: 'Address';
  /** アドレス所有者の名前 */
  name: Scalars['String'];
  /** Emailアドレス */
  email: Scalars['String'];
  /** Emailアドレスのドメイン会社名 */
  companyName: Scalars['String'];
};

/** 配信メールオプション */
export type DeliveryEmailType = {
  __typename?: 'DeliveryEmailType';
  /** 配信メールをどのように設定するか */
  type: DeliveryEmailActionType;
  /** type=Scheduledのとき設定される日付 */
  date?: Maybe<Scalars['DateTime']>;
};

/** 配信グループ作成 */
export type DeliveryEmailInput = {
  /** fromアドレス */
  from: AddressInput;
  /** toアドレス */
  to: Array<AddressInput>;
  /** 件名 */
  subject: Scalars['String'];
  /** 内容 */
  content: Scalars['String'];
  /** オプション */
  deliveryType: DeliveryEmailInputType;
};

/** 入力アドレス */
export type AddressInput = {
  /** アドレス所有者の名前 */
  name: Scalars['String'];
  /** Emailアドレス */
  email: Scalars['String'];
  /** Emailアドレスのドメイン会社名 */
  companyName: Scalars['String'];
};

/** 配信メールオプション(入力) */
export type DeliveryEmailInputType = {
  /** 配信メールをどのように設定するか */
  type: DeliveryEmailActionType;
  /** type=Scheduledのとき設定される日付 */
  date?: Maybe<Scalars['DateTime']>;
};

/** 配信メールをどのように設定するか */
export enum DeliveryEmailActionType {
  /** 下書き */
  Draft = 'Draft',
  /** テスト配信 */
  Test = 'Test',
  /** 即時配信 */
  Immediately = 'Immediately',
  /** 予約配信 */
  Scheduled = 'Scheduled'
}

export type DeliveryEmployee = {
  __typename?: 'DeliveryEmployee';
  /** 配信ユーザーID */
  id: Scalars['ID'];
  /** 配信ユーザーID */
  employeeId: Scalars['ID'];
  /** 配信ユーザーのメールアドレス */
  email: Scalars['Email'];
  /** 宛先従業員の名前 */
  name: Scalars['String'];
  /** 宛先従業員の会社名 */
  companyName: Scalars['String'];
  /** 配信メモ */
  description?: Maybe<Scalars['String']>;
  /** 配信するか */
  delivered: Scalars['Boolean'];
  /** 配信不可かどうか */
  disabled: Scalars['Boolean'];
};

/** 配信グループの宛先作成 */
export type CreateDeliveryEmployeeInput = {
  /** 配信対象の会社員ID */
  employeeId: Scalars['ID'];
  /** 非活性理由 */
  disableReason?: Maybe<Scalars['String']>;
  /** 配信するか */
  delivered: Scalars['Boolean'];
  /** 配信不可かどうか */
  disabled: Scalars['Boolean'];
};

/** 配信グループの宛先更新 */
export type UpdateDeliveryEmployeeInput = {
  /** 配信対象ID */
  id?: Maybe<Scalars['ID']>;
  /** 配信対象の会社員ID */
  employeeId: Scalars['ID'];
  /** 非活性理由 */
  disableReason?: Maybe<Scalars['String']>;
  /** 配信するか */
  delivered: Scalars['Boolean'];
  /** 配信不可かどうか */
  disabled: Scalars['Boolean'];
};

/** 配信グループ検索入力 */
export type DeliveryGroupSearchInput = {
  name?: Maybe<Scalars['String']>;
};

/** 配信グループ一覧 */
export type DeliveryGroups = {
  __typename?: 'DeliveryGroups';
  totalCount: Scalars['Int'];
  nodes: Array<DeliveryGroup>;
};

/** 配信グループ */
export type DeliveryGroup = {
  __typename?: 'DeliveryGroup';
  /** 配信グループID */
  id: Scalars['ID'];
  /** 配信グループの名前 */
  name: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 差出人ID */
  fromUserId: Scalars['ID'];
  /** 差出人 */
  fromUser: User;
  /** 宛先 */
  toEmployees: Array<DeliveryEmployee>;
  /** 更新日 */
  updatedAt: Scalars['DateTime'];
};

/** 配信グループ作成 */
export type CreateDeliveryGroupInput = {
  /** 配信グループの名前 */
  name: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 差出人 */
  fromUserId: Scalars['ID'];
  /** 宛先 */
  toEmployees: Array<CreateDeliveryEmployeeInput>;
};

/** 配信グループ更新 */
export type UpdateDeliveryGroupInput = {
  /** 配信グループのID */
  id: Scalars['ID'];
  /** 配信グループの名前 */
  name: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 差出人 */
  fromUserId: Scalars['ID'];
  /** 宛先 */
  toEmployees: Array<UpdateDeliveryEmployeeInput>;
};

/** 配信履歴検索入力 */
export type DeliveryHistorySearchInput = {
  name?: Maybe<Scalars['String']>;
};

/** 配信履歴一覧 */
export type DeliveryHistories = {
  __typename?: 'DeliveryHistories';
  totalCount: Scalars['Int'];
  nodes: Array<DeliveryHistory>;
};

/** 配信履歴 */
export type DeliveryHistory = {
  __typename?: 'DeliveryHistory';
  /** 配信履歴ID */
  id: Scalars['ID'];
  /** 件名 */
  subject: Scalars['String'];
  /** 内容 */
  content: Scalars['String'];
  /** 配信日 */
  createdAt: Scalars['DateTime'];
  /** 配信者 */
  from: Address;
  /** 配信先 */
  to: Array<Address>;
  /** 配信タイプ */
  deliveryType: DeliveryEmailType;
};

export type CreateDeliveryHistoryInput = {
  groupId: Scalars['ID'];
  subject: Scalars['String'];
  content: Scalars['String'];
};

/** メール登録用入力 */
export type EmailDocumentInput = {
  /** 件名 */
  subject: Scalars['String'];
  /** 内容 */
  content: Scalars['String'];
  /** 受信日 */
  date: Scalars['DateTime'];
  /** 送信元 */
  from: EmailFromInput;
};

/** メール検索フィルター */
export type EmailSearchInput = {
  /** 案件 OR 人材 */
  recruitmentType?: Maybe<RecruitmentType>;
  /** 人材検索用のフィルター */
  hrFilter?: Maybe<HumanResourceFilter>;
  /** 案件検索用のフィルター */
  matterFilter?: Maybe<MatterFilter>;
  /**
   * 日付のしぼりこみ
   * # 1: 今日まで
   * # 2: 1日前まで
   * # 3: 2日前まで
   * # 4: 3日前まで
   * # 5: 4日前まで
   * # 6: 5日前まで
   */
  limitedByDay: Scalars['Int'];
  /** 並び順種別 */
  orderType: OrderType;
  /** フリーテキスト検索 */
  freeWords?: Maybe<Array<Scalars['String']>>;
};

/** Email一覧 */
export type EmailDocuments = {
  __typename?: 'EmailDocuments';
  /** 合計 */
  totalCount: Scalars['Int'];
  /** 一覧 */
  nodes: Array<EmailDocument>;
};

/** Email */
export type EmailDocument = {
  __typename?: 'EmailDocument';
  /** ID */
  id: Scalars['ID'];
  /** 受信日 */
  date: Scalars['DateTime'];
  /** 件名 */
  subject: Scalars['String'];
  /** 内容 */
  content: Scalars['String'];
  /** 送信元 */
  from: EmailFrom;
  /** 案件 OR 人材 */
  recruitmentType: RecruitmentType;
  /** 人材カラム */
  hrColumn?: Maybe<HumanResourceColumn>;
  /** 案件カラム */
  matterColumn?: Maybe<MatterColumn>;
};

/** 人材カラム */
export type HumanResourceColumn = {
  __typename?: 'HumanResourceColumn';
  /** 年齢 */
  age?: Maybe<Scalars['String']>;
  /** 年齢(整数値) */
  ageInt?: Maybe<Scalars['Int']>;
  /** 性別 */
  gender?: Maybe<Scalars['String']>;
  /** 最寄駅 */
  station?: Maybe<Scalars['String']>;
  /** 人材種別 */
  hrTypes?: Maybe<Scalars['String']>;
  /** 稼働次期 */
  startedDate?: Maybe<Scalars['String']>;
  /** 希望金額 */
  reward?: Maybe<Scalars['String']>;
  /** 希望金額(整数値) */
  rewardInt?: Maybe<Scalars['Int']>;
  /** リモート希望かどうか */
  isRemoteNeeded?: Maybe<Scalars['Boolean']>;
};

/** 案件カラム */
export type MatterColumn = {
  __typename?: 'MatterColumn';
  /** 現場駅 */
  station?: Maybe<Scalars['String']>;
  /** リモートかどうか */
  isRemoteEnabled?: Maybe<Scalars['Boolean']>;
  /** 期間 */
  period?: Maybe<Scalars['String']>;
  /** 単価 */
  reward?: Maybe<Scalars['String']>;
  /** 単価(整数値) */
  rewardInt?: Maybe<Scalars['Int']>;
  /** 精算幅、固定 */
  workingTimeWidth?: Maybe<Scalars['String']>;
  /** 外国籍ＯＫ */
  otherCountriesEnabled?: Maybe<Scalars['Boolean']>;
  /** 年齢 */
  age?: Maybe<Scalars['String']>;
  /** 性別 */
  gender?: Maybe<Scalars['String']>;
  /** 面談回数 */
  numberOfInterviews?: Maybe<Scalars['String']>;
  /** 商流制限 */
  commercialDistributionLimitation?: Maybe<Scalars['String']>;
  /** 元請直/エンド直 */
  endOrPrimeContractorDirect?: Maybe<Scalars['String']>;
};

/** 送信元 */
export type EmailFrom = {
  __typename?: 'EmailFrom';
  /** 送信元のメールアドレス */
  address: Scalars['String'];
  /** 送信元の名前 */
  name: Scalars['String'];
};

/** Open Search 削除API実行結果 */
export type OsDeletedResult = {
  __typename?: 'OsDeletedResult';
  _index: Scalars['String'];
  _type: Scalars['String'];
  _id: Scalars['String'];
  _version: Scalars['Int'];
  result: Scalars['String'];
  _shards: OsShards;
  _seq_no: Scalars['Int'];
  _primary_term: Scalars['Int'];
};

/** 人材検索用のフィルター */
export type HumanResourceFilter = {
  /** 男性 OR 女性 */
  genderType?: Maybe<GenderType>;
  /** 年齢 (from 〜 to) */
  ageRange?: Maybe<AgeRangeInput>;
  /** 単価 (from 〜 to) */
  rewardRange?: Maybe<RewardRangeInput>;
  /** 所属形態 */
  hrTypes?: Maybe<HumanResourceTypes>;
  /** 直属かどうか */
  immediately?: Maybe<Scalars['Boolean']>;
  /** リモート希望か */
  needRemote?: Maybe<Scalars['Boolean']>;
  /** 日本籍のみ */
  onlyJapanese?: Maybe<Scalars['Boolean']>;
};

/** 案件検索用のフィルター */
export type MatterFilter = {
  /** 単価 (from 〜 to) */
  rewardRange?: Maybe<RewardRangeInput>;
  /** エンドからの距離(何社先か) */
  distanceFromEnd?: Maybe<Scalars['Int']>;
  /** 面談が1回かどうか */
  oneOpportunity: Scalars['Boolean'];
  /** 外国籍がOKかどうか */
  otherCountriesEnabled: Scalars['Boolean'];
  /** フリーランスNG以外のみ */
  onlyEnableFreelance: Scalars['Boolean'];
  /** リモートワーク可能かどうか */
  enableRemote: Scalars['Boolean'];
};

/** 人材検索用のフィルター */
export type HumanResourceTypes = {
  /** フリーランス */
  freerance: Scalars['Boolean'];
  /** 契約社員 */
  contractEmployee: Scalars['Boolean'];
  /** 正社員 */
  fullTimeEmployee: Scalars['Boolean'];
};

/** 年齢検索用のフィルター */
export type AgeRangeInput = {
  /** from */
  from?: Maybe<Scalars['Int']>;
  /** to */
  to?: Maybe<Scalars['Int']>;
};

/** 単価検索用のフィルター */
export type RewardRangeInput = {
  /** from */
  from?: Maybe<Scalars['Int']>;
  /** to */
  to?: Maybe<Scalars['Int']>;
};

/** 案件種別 */
export enum RecruitmentType {
  /** 案件 */
  Matter = 'Matter',
  /** 人材 */
  HumanResource = 'HumanResource'
}

/** 並び順種別 */
export enum OrderType {
  /** 日付昇順 */
  DateAsc = 'DateAsc',
  /** 日付降順 */
  DateDesc = 'DateDesc',
  /** 年齢昇順 */
  AgeAsc = 'AgeAsc',
  /** 年齢降順 */
  AgeDesc = 'AgeDesc',
  /** 単価昇順 */
  RewardAsc = 'RewardAsc',
  /** 単価降順 */
  RewardDesc = 'RewardDesc'
}

/** 性別 */
export enum GenderType {
  /** 男性 */
  Male = 'Male',
  /** 女性 */
  Female = 'Female'
}

/** Emailの送信元 */
export type EmailFromInput = {
  /** emailアドレス */
  address: Scalars['String'];
  /** 送信元の名前 */
  name: Scalars['String'];
};

/** Open Searchのドキュメント作成結果 */
export type OsCreatedResult = {
  __typename?: 'OsCreatedResult';
  _index: Scalars['String'];
  _type: Scalars['String'];
  _id: Scalars['String'];
  _version: Scalars['Int'];
  result: Scalars['String'];
  _shards: OsShards;
  _seq_no: Scalars['Int'];
  _primary_term: Scalars['Int'];
};

/** Open Searchのドキュメント作成結果サマリ */
export type OsShards = {
  __typename?: 'OsShards';
  total: Scalars['Int'];
  successful: Scalars['Int'];
  failed: Scalars['Int'];
};

/** 送信者 */
export type SenderInput = {
  /** 送信者名 */
  name: Scalars['String'];
  /** 送信者Emailアドレス */
  email: Scalars['String'];
};

/** 従業員検索入力 */
export type EmployeeSearchInput = {
  name?: Maybe<Scalars['String']>;
};

/** 従業員一覧 */
export type Employees = {
  __typename?: 'Employees';
  totalCount: Scalars['Int'];
  nodes: Array<Employee>;
};

/** 従業員 */
export type Employee = {
  __typename?: 'Employee';
  /** 従業員ID */
  id: Scalars['ID'];
  /** メールアドレス */
  email: Scalars['Email'];
  /** 従業員の名前 */
  name: Scalars['String'];
  /** 従業員のよみがな */
  kana?: Maybe<Scalars['String']>;
  /** 会社ID */
  companyId: Scalars['ID'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 有効/無効 */
  disabled: Scalars['Boolean'];
  /** 無効な理由 */
  disabledReason?: Maybe<Scalars['String']>;
};

/** 従業員作成 */
export type CreateEmployeeInput = {
  /** メールアドレス */
  email: Scalars['Email'];
  /** 従業員の名前 */
  name: Scalars['String'];
  /** 従業員のよみがな */
  kana?: Maybe<Scalars['String']>;
  /** 会社ID */
  companyId: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 有効/無効 */
  disabled: Scalars['Boolean'];
  /** 無効な理由 */
  disabledReason?: Maybe<Scalars['String']>;
};

/** 従業員更新 */
export type UpdateEmployeeInput = {
  /** 従業員ID */
  id: Scalars['ID'];
  /** メールアドレス */
  email: Scalars['Email'];
  /** 従業員の名前 */
  name: Scalars['String'];
  /** 従業員のよみがな */
  kana?: Maybe<Scalars['String']>;
  /** 会社ID */
  companyId: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
  /** 有効/無効 */
  disabled: Scalars['Boolean'];
  /** 無効な理由 */
  disabledReason?: Maybe<Scalars['String']>;
};

/**
 * ライセンス作成
 * 会社IDと1:1で紐づく
 */
export type CreateLicenseInput = {
  /** ライセンスの説明 */
  description?: Maybe<Scalars['String']>;
  /** 会社ID */
  companyId: Scalars['ID'];
};

/** ライセンス一覧 */
export type Licenses = {
  __typename?: 'Licenses';
  /** 合計 */
  totalCount: Scalars['Int'];
  /** ライセンス一覧 */
  nodes: Array<License>;
};

/** ライセンス */
export type License = {
  __typename?: 'License';
  /** ライセンスID */
  id: Scalars['ID'];
  /** 会社ID */
  companyId: Scalars['ID'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
};

/** 案件入力 */
export type Matter = {
  __typename?: 'Matter';
  /** 案件ID */
  id: Scalars['ID'];
  /** 案件タイトル */
  title: Scalars['String'];
  /** 案件内容 */
  description?: Maybe<Scalars['String']>;
};

/** 一斉送信入力 */
export type BroadcastMatterInput = {
  /** 送信者 */
  sender: SenderInput;
  /** 件名 */
  subject: Scalars['String'];
  /** 内容 */
  content: Scalars['String'];
  /** 従業員ID */
  employeeIds: Array<Scalars['String']>;
  /** 添付ファイル */
  base64Attachments: Array<Scalars['String']>;
};

/** 案件入力 */
export type MatterInput = {
  /** 案件名 */
  title: Scalars['String'];
  /** 説明 */
  description?: Maybe<Scalars['String']>;
};

export type ExecRawQueryInput = {
  endpointType: EndpointType;
  method: RequestMethod;
  path: Scalars['String'];
  body?: Maybe<Scalars['JSON']>;
};

export enum EndpointType {
  TechReisDev = 'TechReisDev',
  TechReisPrd = 'TechReisPrd',
  RiisaConsul = 'RiisaConsul'
}

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE'
}

/** ロール一覧 */
export type Roles = {
  __typename?: 'Roles';
  totalCount: Scalars['Int'];
  nodes: Array<Role>;
};

/** ロール */
export type Role = {
  __typename?: 'Role';
  /** ロールId */
  id: Scalars['ID'];
  /** ロール名 */
  name: Scalars['String'];
  /** 案件内容 */
  description?: Maybe<Scalars['String']>;
};

/** ロール入力 */
export type RoleInput = {
  /** ロールId */
  id: Scalars['ID'];
  /** ロール名 */
  name: Scalars['String'];
  /** 案件内容 */
  description?: Maybe<Scalars['String']>;
};





export type TodoInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type Todo = {
  __typename?: 'Todo';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDone: Scalars['Boolean'];
};

/** ユーザー一覧 */
export type Users = {
  __typename?: 'Users';
  totalCount: Scalars['Int'];
  nodes: Array<User>;
};

/** ユーザー */
export type User = {
  __typename?: 'User';
  /** ID(Cognito Unique Id) */
  id: Scalars['ID'];
  /** ユーザーID(メールアドレス) */
  userId: Scalars['ID'];
  /** 組織ID */
  organizationId: Scalars['ID'];
  /** 姓 */
  familyName: Scalars['String'];
  /** 名 */
  givenName: Scalars['String'];
  /** 姓のフリガナ */
  familyNameKana?: Maybe<Scalars['String']>;
  /** 名のフリガナ */
  givenNameKana?: Maybe<Scalars['String']>;
  /** メールアドレス */
  email: Scalars['Email'];
};

/** ユーザー検索 */
export type UserSearchInput = {
  name?: Maybe<Scalars['String']>;
};

/** ユーザー作成 */
export type UpdateUserInput = {
  /** ID(Cognito Unique Id) */
  id: Scalars['ID'];
  /** 姓 */
  familyName: Scalars['String'];
  /** 名 */
  givenName: Scalars['String'];
  /** 姓のフリガナ */
  familyNameKana: Scalars['String'];
  /** 名のフリガナ */
  givenNameKana: Scalars['String'];
  /** メールアドレス */
  email: Scalars['Email'];
  /** 会社 */
  companyId?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type CreateTodoMutationVariables = Exact<{
  input: TodoInput;
}>;


export type CreateTodoMutation = (
  { __typename?: 'Mutation' }
  & { createTodo?: Maybe<(
    { __typename?: 'Todo' }
    & Pick<Todo, 'id' | 'title' | 'description' | 'isDone'>
  )> }
);

export type IsUserLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IsUserLoggedInQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isLoggedIn'>
);

export type TodoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TodoQuery = (
  { __typename?: 'Query' }
  & { todo?: Maybe<(
    { __typename?: 'Todo' }
    & Pick<Todo, 'id' | 'title' | 'description' | 'isDone'>
  )> }
);

export type TodosQueryVariables = Exact<{ [key: string]: never; }>;


export type TodosQuery = (
  { __typename?: 'Query' }
  & { todos?: Maybe<Array<(
    { __typename?: 'Todo' }
    & Pick<Todo, 'id' | 'title' | 'description' | 'isDone'>
  )>> }
);

export type SelectItemUsersFragment = (
  { __typename?: 'Users' }
  & Pick<Users, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'User' }
    & SelectItemUserFragment
  )> }
);

export type SelectItemUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'userId' | 'familyName' | 'givenName'>
);

export type SelectItemEmployeesFragment = (
  { __typename?: 'Employees' }
  & Pick<Employees, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'Employee' }
    & SelectItemEmployeeFragment
  )> }
);

export type SelectItemEmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'email'>
);

export type SelectItemCompaniesFragment = (
  { __typename?: 'Companies' }
  & Pick<Companies, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'Company' }
    & SelectItemCompanyFragment
  )> }
);

export type SelectItemCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
);

export type LayoutMeFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'userId' | 'organizationId' | 'familyName' | 'givenName' | 'email'>
);

export type LayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LayoutQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & LayoutMeFragment
  ) }
);

export type HomePageUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'userId' | 'organizationId' | 'familyName' | 'givenName' | 'email'>
);

export type HomePageQueryVariables = Exact<{ [key: string]: never; }>;


export type HomePageQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & HomePageUserFragment
  ) }
);

export type CompaniesItemFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'description' | 'yearOfEstablishment' | 'capital' | 'numberOfEmployees' | 'numberOfEngineers' | 'numberOfFreelancers' | 'numberOfBusinessPartners' | 'numberOfBPEngineers' | 'numberOfSales' | 'deliveryEmail' | 'zip' | 'prefecture' | 'address1' | 'address2' | 'connecterId' | 'disabled' | 'disabledReason'>
  & { connecter: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'givenName' | 'familyName'>
  ) }
);

export type CompaniesFragment = (
  { __typename?: 'Companies' }
  & Pick<Companies, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'Company' }
    & CompaniesItemFragment
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type CompaniesPageQueryVariables = Exact<{
  filter?: Maybe<CompanySearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type CompaniesPageQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'Companies' }
    & CompaniesFragment
  ), users: (
    { __typename?: 'Users' }
    & SelectItemUsersFragment
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
);

export type SelectItemUsersQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SelectItemUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'Users' }
    & SelectItemUsersFragment
  ) }
);

export type CreateDeliveryGroupMutationVariables = Exact<{
  input: CreateDeliveryGroupInput;
}>;


export type CreateDeliveryGroupMutation = (
  { __typename?: 'Mutation' }
  & { createDeliveryGroup: (
    { __typename?: 'DeliveryGroup' }
    & Pick<DeliveryGroup, 'id' | 'name'>
  ) }
);

export type DeliveryGroupNewPageQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type DeliveryGroupNewPageQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'Employees' }
    & SelectItemEmployeesFragment
  ), users: (
    { __typename?: 'Users' }
    & SelectItemUsersFragment
  ) }
);

export type DeliveryGroupsItemFragment = (
  { __typename?: 'DeliveryGroup' }
  & Pick<DeliveryGroup, 'id' | 'name' | 'description' | 'updatedAt' | 'fromUserId'>
  & { fromUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'familyName' | 'givenName' | 'email'>
  ), toEmployees: Array<(
    { __typename?: 'DeliveryEmployee' }
    & DeliveryEmployeeFragment
  )> }
);

export type DeliveryEmployeeFragment = (
  { __typename?: 'DeliveryEmployee' }
  & Pick<DeliveryEmployee, 'id' | 'name' | 'email' | 'employeeId' | 'description' | 'delivered' | 'disabled' | 'companyName'>
);

export type DeliveryGroupsFragment = (
  { __typename?: 'DeliveryGroups' }
  & Pick<DeliveryGroups, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'DeliveryGroup' }
    & DeliveryGroupsItemFragment
  )> }
);

export type UpdateDeliveryGroupMutationVariables = Exact<{
  input: UpdateDeliveryGroupInput;
}>;


export type UpdateDeliveryGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateDeliveryGroup: (
    { __typename?: 'DeliveryGroup' }
    & Pick<DeliveryGroup, 'id' | 'name'>
  ) }
);

export type DeleteDeliveryGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDeliveryGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDeliveryGroup'>
);

export type DeliveryGroupsPageQueryVariables = Exact<{
  filter?: Maybe<DeliveryGroupSearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type DeliveryGroupsPageQuery = (
  { __typename?: 'Query' }
  & { deliveryGroups: (
    { __typename?: 'DeliveryGroups' }
    & DeliveryGroupsFragment
  ), employees: (
    { __typename?: 'Employees' }
    & SelectItemEmployeesFragment
  ), users: (
    { __typename?: 'Users' }
    & SelectItemUsersFragment
  ) }
);

export type BroadcastMatterMutationVariables = Exact<{
  input: BroadcastMatterInput;
}>;


export type BroadcastMatterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'broadcastMatter'>
);

export type DeliveryHistoriesItemFragment = (
  { __typename?: 'DeliveryHistory' }
  & Pick<DeliveryHistory, 'id' | 'subject' | 'content' | 'createdAt'>
  & { from: (
    { __typename?: 'Address' }
    & Pick<Address, 'name' | 'email' | 'companyName'>
  ), to: Array<(
    { __typename?: 'Address' }
    & Pick<Address, 'name' | 'email' | 'companyName'>
  )>, deliveryType: (
    { __typename?: 'DeliveryEmailType' }
    & Pick<DeliveryEmailType, 'type'>
  ) }
);

export type DeliveryHistoriesFragment = (
  { __typename?: 'DeliveryHistories' }
  & Pick<DeliveryHistories, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'DeliveryHistory' }
    & DeliveryHistoriesItemFragment
  )> }
);

export type DeliveryHistoriesPageQueryVariables = Exact<{
  filter?: Maybe<DeliveryHistorySearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type DeliveryHistoriesPageQuery = (
  { __typename?: 'Query' }
  & { deliveryHistories: (
    { __typename?: 'DeliveryHistories' }
    & DeliveryHistoriesFragment
  ) }
);

export type DeliveryEmailMutationVariables = Exact<{
  input: DeliveryEmailInput;
}>;


export type DeliveryEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deliveryEmail'>
);

export type DeliveryEmailNewPageQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type DeliveryEmailNewPageQuery = (
  { __typename?: 'Query' }
  & { deliveryGroups: (
    { __typename?: 'DeliveryGroups' }
    & DeliveryGroupsFragment
  ) }
);

export type EmailFragment = (
  { __typename?: 'EmailDocument' }
  & Pick<EmailDocument, 'id' | 'subject' | 'content' | 'date' | 'recruitmentType'>
  & { hrColumn?: Maybe<(
    { __typename?: 'HumanResourceColumn' }
    & Pick<HumanResourceColumn, 'age' | 'gender' | 'station' | 'reward' | 'startedDate' | 'hrTypes' | 'isRemoteNeeded'>
  )>, matterColumn?: Maybe<(
    { __typename?: 'MatterColumn' }
    & Pick<MatterColumn, 'station' | 'isRemoteEnabled' | 'period' | 'reward' | 'age' | 'gender' | 'workingTimeWidth' | 'otherCountriesEnabled' | 'numberOfInterviews' | 'commercialDistributionLimitation' | 'endOrPrimeContractorDirect'>
  )>, from: (
    { __typename?: 'EmailFrom' }
    & Pick<EmailFrom, 'address' | 'name'>
  ) }
);

export type EmailsFragment = (
  { __typename?: 'EmailDocuments' }
  & Pick<EmailDocuments, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'EmailDocument' }
    & EmailFragment
  )> }
);

export type EmailsQueryVariables = Exact<{
  filter?: Maybe<EmailSearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type EmailsQuery = (
  { __typename?: 'Query' }
  & { emails: (
    { __typename?: 'EmailDocuments' }
    & EmailsFragment
  ) }
);

export type CreateEmployeeMutationVariables = Exact<{
  input: CreateEmployeeInput;
}>;


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ) }
);

export type SelectItemCompaniesQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SelectItemCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'Companies' }
    & SelectItemCompaniesFragment
  ) }
);

export type EmployeesItemFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'email' | 'name' | 'kana' | 'companyId' | 'description' | 'disabled' | 'disabledReason'>
);

export type EmployeesFragment = (
  { __typename?: 'Employees' }
  & Pick<Employees, 'totalCount'>
  & { nodes: Array<(
    { __typename?: 'Employee' }
    & EmployeesItemFragment
  )> }
);

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ) }
);

export type DeleteEmployeeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEmployee'>
);

export type EmployeesPageQueryVariables = Exact<{
  filter?: Maybe<EmployeeSearchInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type EmployeesPageQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'Employees' }
    & EmployeesFragment
  ), companies: (
    { __typename?: 'Companies' }
    & SelectItemCompaniesFragment
  ) }
);

export type BulkCreateCompaniesWithEmployeesMutationVariables = Exact<{
  inputs: Array<CreateCompanyWithEmployeeInput> | CreateCompanyWithEmployeeInput;
}>;


export type BulkCreateCompaniesWithEmployeesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkCreateCompaniesWithEmployees'>
);

export type BulkAllDeleteCompaniesMutationVariables = Exact<{ [key: string]: never; }>;


export type BulkAllDeleteCompaniesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkAllDeleteCompanies'>
);

export const SelectItemUserFragmentDoc = gql`
    fragment SelectItemUser on User {
  id
  userId
  familyName
  givenName
}
    `;
export const SelectItemUsersFragmentDoc = gql`
    fragment SelectItemUsers on Users {
  totalCount
  nodes {
    ...SelectItemUser
  }
}
    ${SelectItemUserFragmentDoc}`;
export const SelectItemEmployeeFragmentDoc = gql`
    fragment SelectItemEmployee on Employee {
  id
  name
  email
}
    `;
export const SelectItemEmployeesFragmentDoc = gql`
    fragment SelectItemEmployees on Employees {
  totalCount
  nodes {
    ...SelectItemEmployee
  }
}
    ${SelectItemEmployeeFragmentDoc}`;
export const SelectItemCompanyFragmentDoc = gql`
    fragment SelectItemCompany on Company {
  id
  name
}
    `;
export const SelectItemCompaniesFragmentDoc = gql`
    fragment SelectItemCompanies on Companies {
  totalCount
  nodes {
    ...SelectItemCompany
  }
}
    ${SelectItemCompanyFragmentDoc}`;
export const LayoutMeFragmentDoc = gql`
    fragment LayoutMe on User {
  id
  userId
  organizationId
  familyName
  givenName
  email
}
    `;
export const HomePageUserFragmentDoc = gql`
    fragment HomePageUser on User {
  id
  userId
  organizationId
  familyName
  givenName
  email
}
    `;
export const CompaniesItemFragmentDoc = gql`
    fragment CompaniesItem on Company {
  id
  name
  description
  yearOfEstablishment
  capital
  numberOfEmployees
  numberOfEngineers
  numberOfFreelancers
  numberOfBusinessPartners
  numberOfBPEngineers
  numberOfSales
  deliveryEmail
  zip
  prefecture
  address1
  address2
  connecterId
  disabled
  disabledReason
  connecter {
    id
    givenName
    familyName
  }
}
    `;
export const CompaniesFragmentDoc = gql`
    fragment Companies on Companies {
  totalCount
  nodes {
    ...CompaniesItem
  }
}
    ${CompaniesItemFragmentDoc}`;
export const DeliveryEmployeeFragmentDoc = gql`
    fragment DeliveryEmployee on DeliveryEmployee {
  id
  name
  email
  employeeId
  description
  delivered
  disabled
  companyName
}
    `;
export const DeliveryGroupsItemFragmentDoc = gql`
    fragment DeliveryGroupsItem on DeliveryGroup {
  id
  name
  description
  updatedAt
  fromUser {
    id
    familyName
    givenName
    email
  }
  fromUserId
  toEmployees {
    ...DeliveryEmployee
  }
}
    ${DeliveryEmployeeFragmentDoc}`;
export const DeliveryGroupsFragmentDoc = gql`
    fragment DeliveryGroups on DeliveryGroups {
  totalCount
  nodes {
    ...DeliveryGroupsItem
  }
}
    ${DeliveryGroupsItemFragmentDoc}`;
export const DeliveryHistoriesItemFragmentDoc = gql`
    fragment DeliveryHistoriesItem on DeliveryHistory {
  id
  subject
  content
  createdAt
  from {
    name
    email
    companyName
  }
  to {
    name
    email
    companyName
  }
  deliveryType {
    type
  }
}
    `;
export const DeliveryHistoriesFragmentDoc = gql`
    fragment DeliveryHistories on DeliveryHistories {
  totalCount
  nodes {
    ...DeliveryHistoriesItem
  }
}
    ${DeliveryHistoriesItemFragmentDoc}`;
export const EmailFragmentDoc = gql`
    fragment Email on EmailDocument {
  id
  subject
  content
  date
  recruitmentType
  hrColumn {
    age
    gender
    station
    reward
    startedDate
    hrTypes
    isRemoteNeeded
  }
  matterColumn {
    station
    isRemoteEnabled
    period
    reward
    age
    gender
    workingTimeWidth
    otherCountriesEnabled
    numberOfInterviews
    commercialDistributionLimitation
    endOrPrimeContractorDirect
  }
  from {
    address
    name
  }
}
    `;
export const EmailsFragmentDoc = gql`
    fragment Emails on EmailDocuments {
  totalCount
  nodes {
    ...Email
  }
}
    ${EmailFragmentDoc}`;
export const EmployeesItemFragmentDoc = gql`
    fragment EmployeesItem on Employee {
  id
  email
  name
  kana
  companyId
  description
  disabled
  disabledReason
}
    `;
export const EmployeesFragmentDoc = gql`
    fragment Employees on Employees {
  totalCount
  nodes {
    ...EmployeesItem
  }
}
    ${EmployeesItemFragmentDoc}`;
export const CreateTodoDocument = gql`
    mutation createTodo($input: TodoInput!) {
  createTodo(input: $input) {
    id
    title
    description
    isDone
  }
}
    `;
export type CreateTodoMutationFn = Apollo.MutationFunction<CreateTodoMutation, CreateTodoMutationVariables>;

/**
 * __useCreateTodoMutation__
 *
 * To run a mutation, you first call `useCreateTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTodoMutation, { data, loading, error }] = useCreateTodoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTodoMutation(baseOptions?: Apollo.MutationHookOptions<CreateTodoMutation, CreateTodoMutationVariables>) {
        return Apollo.useMutation<CreateTodoMutation, CreateTodoMutationVariables>(CreateTodoDocument, baseOptions);
      }
export type CreateTodoMutationHookResult = ReturnType<typeof useCreateTodoMutation>;
export type CreateTodoMutationResult = Apollo.MutationResult<CreateTodoMutation>;
export type CreateTodoMutationOptions = Apollo.BaseMutationOptions<CreateTodoMutation, CreateTodoMutationVariables>;
export const IsUserLoggedInDocument = gql`
    query IsUserLoggedIn {
  isLoggedIn @client
}
    `;

/**
 * __useIsUserLoggedInQuery__
 *
 * To run a query within a React component, call `useIsUserLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserLoggedInQuery(baseOptions?: Apollo.QueryHookOptions<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>) {
        return Apollo.useQuery<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>(IsUserLoggedInDocument, baseOptions);
      }
export function useIsUserLoggedInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>) {
          return Apollo.useLazyQuery<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>(IsUserLoggedInDocument, baseOptions);
        }
export type IsUserLoggedInQueryHookResult = ReturnType<typeof useIsUserLoggedInQuery>;
export type IsUserLoggedInLazyQueryHookResult = ReturnType<typeof useIsUserLoggedInLazyQuery>;
export type IsUserLoggedInQueryResult = Apollo.QueryResult<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>;
export const TodoDocument = gql`
    query todo($id: ID!) {
  todo(id: $id) {
    id
    title
    description
    isDone
  }
}
    `;

/**
 * __useTodoQuery__
 *
 * To run a query within a React component, call `useTodoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTodoQuery(baseOptions: Apollo.QueryHookOptions<TodoQuery, TodoQueryVariables>) {
        return Apollo.useQuery<TodoQuery, TodoQueryVariables>(TodoDocument, baseOptions);
      }
export function useTodoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodoQuery, TodoQueryVariables>) {
          return Apollo.useLazyQuery<TodoQuery, TodoQueryVariables>(TodoDocument, baseOptions);
        }
export type TodoQueryHookResult = ReturnType<typeof useTodoQuery>;
export type TodoLazyQueryHookResult = ReturnType<typeof useTodoLazyQuery>;
export type TodoQueryResult = Apollo.QueryResult<TodoQuery, TodoQueryVariables>;
export const TodosDocument = gql`
    query todos {
  todos {
    id
    title
    description
    isDone
  }
}
    `;

/**
 * __useTodosQuery__
 *
 * To run a query within a React component, call `useTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useTodosQuery(baseOptions?: Apollo.QueryHookOptions<TodosQuery, TodosQueryVariables>) {
        return Apollo.useQuery<TodosQuery, TodosQueryVariables>(TodosDocument, baseOptions);
      }
export function useTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodosQuery, TodosQueryVariables>) {
          return Apollo.useLazyQuery<TodosQuery, TodosQueryVariables>(TodosDocument, baseOptions);
        }
export type TodosQueryHookResult = ReturnType<typeof useTodosQuery>;
export type TodosLazyQueryHookResult = ReturnType<typeof useTodosLazyQuery>;
export type TodosQueryResult = Apollo.QueryResult<TodosQuery, TodosQueryVariables>;
export const LayoutDocument = gql`
    query Layout {
  me {
    ...LayoutMe
  }
}
    ${LayoutMeFragmentDoc}`;

/**
 * __useLayoutQuery__
 *
 * To run a query within a React component, call `useLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, baseOptions);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, baseOptions);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const HomePageDocument = gql`
    query HomePage {
  me {
    ...HomePageUser
  }
}
    ${HomePageUserFragmentDoc}`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageQuery(baseOptions?: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
        return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, baseOptions);
      }
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
          return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, baseOptions);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = Apollo.QueryResult<HomePageQuery, HomePageQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    id
    name
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const CompaniesPageDocument = gql`
    query CompaniesPage($filter: CompanySearchInput, $offset: Int, $limit: Int) {
  companies(filter: $filter, offset: $offset, limit: $limit) {
    ...Companies
  }
  users {
    ...SelectItemUsers
  }
}
    ${CompaniesFragmentDoc}
${SelectItemUsersFragmentDoc}`;

/**
 * __useCompaniesPageQuery__
 *
 * To run a query within a React component, call `useCompaniesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompaniesPageQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesPageQuery, CompaniesPageQueryVariables>) {
        return Apollo.useQuery<CompaniesPageQuery, CompaniesPageQueryVariables>(CompaniesPageDocument, baseOptions);
      }
export function useCompaniesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesPageQuery, CompaniesPageQueryVariables>) {
          return Apollo.useLazyQuery<CompaniesPageQuery, CompaniesPageQueryVariables>(CompaniesPageDocument, baseOptions);
        }
export type CompaniesPageQueryHookResult = ReturnType<typeof useCompaniesPageQuery>;
export type CompaniesPageLazyQueryHookResult = ReturnType<typeof useCompaniesPageLazyQuery>;
export type CompaniesPageQueryResult = Apollo.QueryResult<CompaniesPageQuery, CompaniesPageQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    id
    name
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const SelectItemUsersDocument = gql`
    query SelectItemUsers($offset: Int, $limit: Int) {
  users(offset: $offset, limit: $limit) {
    ...SelectItemUsers
  }
}
    ${SelectItemUsersFragmentDoc}`;

/**
 * __useSelectItemUsersQuery__
 *
 * To run a query within a React component, call `useSelectItemUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectItemUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectItemUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSelectItemUsersQuery(baseOptions?: Apollo.QueryHookOptions<SelectItemUsersQuery, SelectItemUsersQueryVariables>) {
        return Apollo.useQuery<SelectItemUsersQuery, SelectItemUsersQueryVariables>(SelectItemUsersDocument, baseOptions);
      }
export function useSelectItemUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectItemUsersQuery, SelectItemUsersQueryVariables>) {
          return Apollo.useLazyQuery<SelectItemUsersQuery, SelectItemUsersQueryVariables>(SelectItemUsersDocument, baseOptions);
        }
export type SelectItemUsersQueryHookResult = ReturnType<typeof useSelectItemUsersQuery>;
export type SelectItemUsersLazyQueryHookResult = ReturnType<typeof useSelectItemUsersLazyQuery>;
export type SelectItemUsersQueryResult = Apollo.QueryResult<SelectItemUsersQuery, SelectItemUsersQueryVariables>;
export const CreateDeliveryGroupDocument = gql`
    mutation createDeliveryGroup($input: CreateDeliveryGroupInput!) {
  createDeliveryGroup(input: $input) {
    id
    name
  }
}
    `;
export type CreateDeliveryGroupMutationFn = Apollo.MutationFunction<CreateDeliveryGroupMutation, CreateDeliveryGroupMutationVariables>;

/**
 * __useCreateDeliveryGroupMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryGroupMutation, { data, loading, error }] = useCreateDeliveryGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryGroupMutation, CreateDeliveryGroupMutationVariables>) {
        return Apollo.useMutation<CreateDeliveryGroupMutation, CreateDeliveryGroupMutationVariables>(CreateDeliveryGroupDocument, baseOptions);
      }
export type CreateDeliveryGroupMutationHookResult = ReturnType<typeof useCreateDeliveryGroupMutation>;
export type CreateDeliveryGroupMutationResult = Apollo.MutationResult<CreateDeliveryGroupMutation>;
export type CreateDeliveryGroupMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryGroupMutation, CreateDeliveryGroupMutationVariables>;
export const DeliveryGroupNewPageDocument = gql`
    query DeliveryGroupNewPage($offset: Int, $limit: Int) {
  employees(offset: $offset, limit: $limit) {
    ...SelectItemEmployees
  }
  users(offset: $offset, limit: $limit) {
    ...SelectItemUsers
  }
}
    ${SelectItemEmployeesFragmentDoc}
${SelectItemUsersFragmentDoc}`;

/**
 * __useDeliveryGroupNewPageQuery__
 *
 * To run a query within a React component, call `useDeliveryGroupNewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryGroupNewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryGroupNewPageQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDeliveryGroupNewPageQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryGroupNewPageQuery, DeliveryGroupNewPageQueryVariables>) {
        return Apollo.useQuery<DeliveryGroupNewPageQuery, DeliveryGroupNewPageQueryVariables>(DeliveryGroupNewPageDocument, baseOptions);
      }
export function useDeliveryGroupNewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryGroupNewPageQuery, DeliveryGroupNewPageQueryVariables>) {
          return Apollo.useLazyQuery<DeliveryGroupNewPageQuery, DeliveryGroupNewPageQueryVariables>(DeliveryGroupNewPageDocument, baseOptions);
        }
export type DeliveryGroupNewPageQueryHookResult = ReturnType<typeof useDeliveryGroupNewPageQuery>;
export type DeliveryGroupNewPageLazyQueryHookResult = ReturnType<typeof useDeliveryGroupNewPageLazyQuery>;
export type DeliveryGroupNewPageQueryResult = Apollo.QueryResult<DeliveryGroupNewPageQuery, DeliveryGroupNewPageQueryVariables>;
export const UpdateDeliveryGroupDocument = gql`
    mutation updateDeliveryGroup($input: UpdateDeliveryGroupInput!) {
  updateDeliveryGroup(input: $input) {
    id
    name
  }
}
    `;
export type UpdateDeliveryGroupMutationFn = Apollo.MutationFunction<UpdateDeliveryGroupMutation, UpdateDeliveryGroupMutationVariables>;

/**
 * __useUpdateDeliveryGroupMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryGroupMutation, { data, loading, error }] = useUpdateDeliveryGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryGroupMutation, UpdateDeliveryGroupMutationVariables>) {
        return Apollo.useMutation<UpdateDeliveryGroupMutation, UpdateDeliveryGroupMutationVariables>(UpdateDeliveryGroupDocument, baseOptions);
      }
export type UpdateDeliveryGroupMutationHookResult = ReturnType<typeof useUpdateDeliveryGroupMutation>;
export type UpdateDeliveryGroupMutationResult = Apollo.MutationResult<UpdateDeliveryGroupMutation>;
export type UpdateDeliveryGroupMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryGroupMutation, UpdateDeliveryGroupMutationVariables>;
export const DeleteDeliveryGroupDocument = gql`
    mutation deleteDeliveryGroup($id: ID!) {
  deleteDeliveryGroup(id: $id)
}
    `;
export type DeleteDeliveryGroupMutationFn = Apollo.MutationFunction<DeleteDeliveryGroupMutation, DeleteDeliveryGroupMutationVariables>;

/**
 * __useDeleteDeliveryGroupMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryGroupMutation, { data, loading, error }] = useDeleteDeliveryGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeliveryGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeliveryGroupMutation, DeleteDeliveryGroupMutationVariables>) {
        return Apollo.useMutation<DeleteDeliveryGroupMutation, DeleteDeliveryGroupMutationVariables>(DeleteDeliveryGroupDocument, baseOptions);
      }
export type DeleteDeliveryGroupMutationHookResult = ReturnType<typeof useDeleteDeliveryGroupMutation>;
export type DeleteDeliveryGroupMutationResult = Apollo.MutationResult<DeleteDeliveryGroupMutation>;
export type DeleteDeliveryGroupMutationOptions = Apollo.BaseMutationOptions<DeleteDeliveryGroupMutation, DeleteDeliveryGroupMutationVariables>;
export const DeliveryGroupsPageDocument = gql`
    query DeliveryGroupsPage($filter: DeliveryGroupSearchInput, $offset: Int, $limit: Int) {
  deliveryGroups(filter: $filter, offset: $offset, limit: $limit) {
    ...DeliveryGroups
  }
  employees {
    ...SelectItemEmployees
  }
  users {
    ...SelectItemUsers
  }
}
    ${DeliveryGroupsFragmentDoc}
${SelectItemEmployeesFragmentDoc}
${SelectItemUsersFragmentDoc}`;

/**
 * __useDeliveryGroupsPageQuery__
 *
 * To run a query within a React component, call `useDeliveryGroupsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryGroupsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryGroupsPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDeliveryGroupsPageQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryGroupsPageQuery, DeliveryGroupsPageQueryVariables>) {
        return Apollo.useQuery<DeliveryGroupsPageQuery, DeliveryGroupsPageQueryVariables>(DeliveryGroupsPageDocument, baseOptions);
      }
export function useDeliveryGroupsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryGroupsPageQuery, DeliveryGroupsPageQueryVariables>) {
          return Apollo.useLazyQuery<DeliveryGroupsPageQuery, DeliveryGroupsPageQueryVariables>(DeliveryGroupsPageDocument, baseOptions);
        }
export type DeliveryGroupsPageQueryHookResult = ReturnType<typeof useDeliveryGroupsPageQuery>;
export type DeliveryGroupsPageLazyQueryHookResult = ReturnType<typeof useDeliveryGroupsPageLazyQuery>;
export type DeliveryGroupsPageQueryResult = Apollo.QueryResult<DeliveryGroupsPageQuery, DeliveryGroupsPageQueryVariables>;
export const BroadcastMatterDocument = gql`
    mutation broadcastMatter($input: BroadcastMatterInput!) {
  broadcastMatter(input: $input)
}
    `;
export type BroadcastMatterMutationFn = Apollo.MutationFunction<BroadcastMatterMutation, BroadcastMatterMutationVariables>;

/**
 * __useBroadcastMatterMutation__
 *
 * To run a mutation, you first call `useBroadcastMatterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastMatterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastMatterMutation, { data, loading, error }] = useBroadcastMatterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBroadcastMatterMutation(baseOptions?: Apollo.MutationHookOptions<BroadcastMatterMutation, BroadcastMatterMutationVariables>) {
        return Apollo.useMutation<BroadcastMatterMutation, BroadcastMatterMutationVariables>(BroadcastMatterDocument, baseOptions);
      }
export type BroadcastMatterMutationHookResult = ReturnType<typeof useBroadcastMatterMutation>;
export type BroadcastMatterMutationResult = Apollo.MutationResult<BroadcastMatterMutation>;
export type BroadcastMatterMutationOptions = Apollo.BaseMutationOptions<BroadcastMatterMutation, BroadcastMatterMutationVariables>;
export const DeliveryHistoriesPageDocument = gql`
    query DeliveryHistoriesPage($filter: DeliveryHistorySearchInput, $offset: Int, $limit: Int) {
  deliveryHistories(filter: $filter, offset: $offset, limit: $limit) {
    ...DeliveryHistories
  }
}
    ${DeliveryHistoriesFragmentDoc}`;

/**
 * __useDeliveryHistoriesPageQuery__
 *
 * To run a query within a React component, call `useDeliveryHistoriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryHistoriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryHistoriesPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDeliveryHistoriesPageQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryHistoriesPageQuery, DeliveryHistoriesPageQueryVariables>) {
        return Apollo.useQuery<DeliveryHistoriesPageQuery, DeliveryHistoriesPageQueryVariables>(DeliveryHistoriesPageDocument, baseOptions);
      }
export function useDeliveryHistoriesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryHistoriesPageQuery, DeliveryHistoriesPageQueryVariables>) {
          return Apollo.useLazyQuery<DeliveryHistoriesPageQuery, DeliveryHistoriesPageQueryVariables>(DeliveryHistoriesPageDocument, baseOptions);
        }
export type DeliveryHistoriesPageQueryHookResult = ReturnType<typeof useDeliveryHistoriesPageQuery>;
export type DeliveryHistoriesPageLazyQueryHookResult = ReturnType<typeof useDeliveryHistoriesPageLazyQuery>;
export type DeliveryHistoriesPageQueryResult = Apollo.QueryResult<DeliveryHistoriesPageQuery, DeliveryHistoriesPageQueryVariables>;
export const DeliveryEmailDocument = gql`
    mutation deliveryEmail($input: DeliveryEmailInput!) {
  deliveryEmail(input: $input)
}
    `;
export type DeliveryEmailMutationFn = Apollo.MutationFunction<DeliveryEmailMutation, DeliveryEmailMutationVariables>;

/**
 * __useDeliveryEmailMutation__
 *
 * To run a mutation, you first call `useDeliveryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryEmailMutation, { data, loading, error }] = useDeliveryEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliveryEmailMutation(baseOptions?: Apollo.MutationHookOptions<DeliveryEmailMutation, DeliveryEmailMutationVariables>) {
        return Apollo.useMutation<DeliveryEmailMutation, DeliveryEmailMutationVariables>(DeliveryEmailDocument, baseOptions);
      }
export type DeliveryEmailMutationHookResult = ReturnType<typeof useDeliveryEmailMutation>;
export type DeliveryEmailMutationResult = Apollo.MutationResult<DeliveryEmailMutation>;
export type DeliveryEmailMutationOptions = Apollo.BaseMutationOptions<DeliveryEmailMutation, DeliveryEmailMutationVariables>;
export const DeliveryEmailNewPageDocument = gql`
    query DeliveryEmailNewPage($offset: Int, $limit: Int) {
  deliveryGroups(offset: $offset, limit: $limit) {
    ...DeliveryGroups
  }
}
    ${DeliveryGroupsFragmentDoc}`;

/**
 * __useDeliveryEmailNewPageQuery__
 *
 * To run a query within a React component, call `useDeliveryEmailNewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryEmailNewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryEmailNewPageQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDeliveryEmailNewPageQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryEmailNewPageQuery, DeliveryEmailNewPageQueryVariables>) {
        return Apollo.useQuery<DeliveryEmailNewPageQuery, DeliveryEmailNewPageQueryVariables>(DeliveryEmailNewPageDocument, baseOptions);
      }
export function useDeliveryEmailNewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryEmailNewPageQuery, DeliveryEmailNewPageQueryVariables>) {
          return Apollo.useLazyQuery<DeliveryEmailNewPageQuery, DeliveryEmailNewPageQueryVariables>(DeliveryEmailNewPageDocument, baseOptions);
        }
export type DeliveryEmailNewPageQueryHookResult = ReturnType<typeof useDeliveryEmailNewPageQuery>;
export type DeliveryEmailNewPageLazyQueryHookResult = ReturnType<typeof useDeliveryEmailNewPageLazyQuery>;
export type DeliveryEmailNewPageQueryResult = Apollo.QueryResult<DeliveryEmailNewPageQuery, DeliveryEmailNewPageQueryVariables>;
export const EmailsDocument = gql`
    query Emails($filter: EmailSearchInput, $offset: Int, $limit: Int) {
  emails(filter: $filter, offset: $offset, limit: $limit) {
    ...Emails
  }
}
    ${EmailsFragmentDoc}`;

/**
 * __useEmailsQuery__
 *
 * To run a query within a React component, call `useEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEmailsQuery(baseOptions?: Apollo.QueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
        return Apollo.useQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, baseOptions);
      }
export function useEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
          return Apollo.useLazyQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, baseOptions);
        }
export type EmailsQueryHookResult = ReturnType<typeof useEmailsQuery>;
export type EmailsLazyQueryHookResult = ReturnType<typeof useEmailsLazyQuery>;
export type EmailsQueryResult = Apollo.QueryResult<EmailsQuery, EmailsQueryVariables>;
export const CreateEmployeeDocument = gql`
    mutation createEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    id
    name
  }
}
    `;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, baseOptions);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const SelectItemCompaniesDocument = gql`
    query SelectItemCompanies($offset: Int, $limit: Int) {
  companies(offset: $offset, limit: $limit) {
    ...SelectItemCompanies
  }
}
    ${SelectItemCompaniesFragmentDoc}`;

/**
 * __useSelectItemCompaniesQuery__
 *
 * To run a query within a React component, call `useSelectItemCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectItemCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectItemCompaniesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSelectItemCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<SelectItemCompaniesQuery, SelectItemCompaniesQueryVariables>) {
        return Apollo.useQuery<SelectItemCompaniesQuery, SelectItemCompaniesQueryVariables>(SelectItemCompaniesDocument, baseOptions);
      }
export function useSelectItemCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectItemCompaniesQuery, SelectItemCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<SelectItemCompaniesQuery, SelectItemCompaniesQueryVariables>(SelectItemCompaniesDocument, baseOptions);
        }
export type SelectItemCompaniesQueryHookResult = ReturnType<typeof useSelectItemCompaniesQuery>;
export type SelectItemCompaniesLazyQueryHookResult = ReturnType<typeof useSelectItemCompaniesLazyQuery>;
export type SelectItemCompaniesQueryResult = Apollo.QueryResult<SelectItemCompaniesQuery, SelectItemCompaniesQueryVariables>;
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    id
    name
  }
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, baseOptions);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DeleteEmployeeDocument = gql`
    mutation deleteEmployee($id: ID!) {
  deleteEmployee(id: $id)
}
    `;
export type DeleteEmployeeMutationFn = Apollo.MutationFunction<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;

/**
 * __useDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeMutation, { data, loading, error }] = useDeleteEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>) {
        return Apollo.useMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, baseOptions);
      }
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = Apollo.MutationResult<DeleteEmployeeMutation>;
export type DeleteEmployeeMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export const EmployeesPageDocument = gql`
    query EmployeesPage($filter: EmployeeSearchInput, $offset: Int, $limit: Int) {
  employees(filter: $filter, offset: $offset, limit: $limit) {
    ...Employees
  }
  companies {
    ...SelectItemCompanies
  }
}
    ${EmployeesFragmentDoc}
${SelectItemCompaniesFragmentDoc}`;

/**
 * __useEmployeesPageQuery__
 *
 * To run a query within a React component, call `useEmployeesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEmployeesPageQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesPageQuery, EmployeesPageQueryVariables>) {
        return Apollo.useQuery<EmployeesPageQuery, EmployeesPageQueryVariables>(EmployeesPageDocument, baseOptions);
      }
export function useEmployeesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesPageQuery, EmployeesPageQueryVariables>) {
          return Apollo.useLazyQuery<EmployeesPageQuery, EmployeesPageQueryVariables>(EmployeesPageDocument, baseOptions);
        }
export type EmployeesPageQueryHookResult = ReturnType<typeof useEmployeesPageQuery>;
export type EmployeesPageLazyQueryHookResult = ReturnType<typeof useEmployeesPageLazyQuery>;
export type EmployeesPageQueryResult = Apollo.QueryResult<EmployeesPageQuery, EmployeesPageQueryVariables>;
export const BulkCreateCompaniesWithEmployeesDocument = gql`
    mutation bulkCreateCompaniesWithEmployees($inputs: [CreateCompanyWithEmployeeInput!]!) {
  bulkCreateCompaniesWithEmployees(inputs: $inputs)
}
    `;
export type BulkCreateCompaniesWithEmployeesMutationFn = Apollo.MutationFunction<BulkCreateCompaniesWithEmployeesMutation, BulkCreateCompaniesWithEmployeesMutationVariables>;

/**
 * __useBulkCreateCompaniesWithEmployeesMutation__
 *
 * To run a mutation, you first call `useBulkCreateCompaniesWithEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateCompaniesWithEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateCompaniesWithEmployeesMutation, { data, loading, error }] = useBulkCreateCompaniesWithEmployeesMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useBulkCreateCompaniesWithEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateCompaniesWithEmployeesMutation, BulkCreateCompaniesWithEmployeesMutationVariables>) {
        return Apollo.useMutation<BulkCreateCompaniesWithEmployeesMutation, BulkCreateCompaniesWithEmployeesMutationVariables>(BulkCreateCompaniesWithEmployeesDocument, baseOptions);
      }
export type BulkCreateCompaniesWithEmployeesMutationHookResult = ReturnType<typeof useBulkCreateCompaniesWithEmployeesMutation>;
export type BulkCreateCompaniesWithEmployeesMutationResult = Apollo.MutationResult<BulkCreateCompaniesWithEmployeesMutation>;
export type BulkCreateCompaniesWithEmployeesMutationOptions = Apollo.BaseMutationOptions<BulkCreateCompaniesWithEmployeesMutation, BulkCreateCompaniesWithEmployeesMutationVariables>;
export const BulkAllDeleteCompaniesDocument = gql`
    mutation bulkAllDeleteCompanies {
  bulkAllDeleteCompanies
}
    `;
export type BulkAllDeleteCompaniesMutationFn = Apollo.MutationFunction<BulkAllDeleteCompaniesMutation, BulkAllDeleteCompaniesMutationVariables>;

/**
 * __useBulkAllDeleteCompaniesMutation__
 *
 * To run a mutation, you first call `useBulkAllDeleteCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAllDeleteCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAllDeleteCompaniesMutation, { data, loading, error }] = useBulkAllDeleteCompaniesMutation({
 *   variables: {
 *   },
 * });
 */
export function useBulkAllDeleteCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<BulkAllDeleteCompaniesMutation, BulkAllDeleteCompaniesMutationVariables>) {
        return Apollo.useMutation<BulkAllDeleteCompaniesMutation, BulkAllDeleteCompaniesMutationVariables>(BulkAllDeleteCompaniesDocument, baseOptions);
      }
export type BulkAllDeleteCompaniesMutationHookResult = ReturnType<typeof useBulkAllDeleteCompaniesMutation>;
export type BulkAllDeleteCompaniesMutationResult = Apollo.MutationResult<BulkAllDeleteCompaniesMutation>;
export type BulkAllDeleteCompaniesMutationOptions = Apollo.BaseMutationOptions<BulkAllDeleteCompaniesMutation, BulkAllDeleteCompaniesMutationVariables>;