import React, { HTMLAttributes } from "react";
import RoadSign from "./road_sign.svg";
import OneOnOne from "./one_on_one.svg";
import InterviewLesson from "./interview_lesson.svg";
import ResumeCheck from "./resume_check.svg";
import FreelancersTool from "./freelancers_tool.svg";
import { Support, SupportCard } from "../SupportCard";
type ForTobeFreelancersTabContentProps = HTMLAttributes<HTMLDivElement>;

export const ForTobeFreelancersTabContent: React.FC<
  ForTobeFreelancersTabContentProps
> = ({ className }) => {
  const supports: Support[] = [
    {
      id: 1,
      title: "フリーランス転向支援",
      description:
        "フリーランスに転向するのが不安なら当サービスにお任せください！開業届けや確定申告の手続きから契約書の作成や請求書の発行まで、必要な事務作業をサポートいたします。専属のスタッフが丁寧にアドバイスし、フリーランスとして働くための不安を取り除くお手伝いをします。",
      src: FreelancersTool,
    },
    {
      id: 2,
      title: "キャリアアドバイス",
      description:
        "フリーランスになるには自分に合った案件を選ぶことが重要ですが、市場やスキルの把握は難しいため、当サービスでは専門スタッフが最適な案件を提案し、契約や報酬のサポートも行います。自分に合った案件を見つけて、充実したキャリアを築きましょう。",
      src: RoadSign,
    },
    {
      id: 3,
      title: "スキルシート添削支援",
      description:
        "より多くの面談依頼をもらえるように、あなたのスキルシートを丁寧に添削いたします。専門知識を持った専属スタッフが、あなたの経歴を最適化するためのアドバイスや改善点を見つけ出し、クライアントにアピールするための魅力的な履歴書を作成します。あなたのスキルを最大限に生かし、自信を持ってフリーランスキャリアをスタートしましょう。",
      src: ResumeCheck,
    },
    {
      id: 4,
      title: "面談トレーニング",
      description: `面談の流れを事前に確認することで、よりリラックスして面接に臨めます。またクライアントに好印象を持ってもらうためには重要なポイントが２つありますが、私たちの専任スタッフが面談トレーニングを通じてあなたの魅力を最大限に引き出すお手伝いをいたします。`,
      src: InterviewLesson,
    },
    {
      id: 5,
      title: "現役フリーランスエンジニアとの1on1",
      description:
        "フリーランスエンジニアと1対1での相談ができます。経験豊富なエンジニアと具体的な悩みや方針を相談することで、進む方向性を明確にし不安を解消できます。",
      src: OneOnOne,
    },
  ];
  return (
    <div className={`${className}`}>
      {supports.map((support: Support, index: number) => (
        <SupportCard key={index} support={support} />
      ))}
    </div>
  );
};
