import gift from "./gift.svg";

export const TopSection: React.FC = () => {
  return (
    <div className="hero bg-base-100 py-16">
      <div className="hero-content max-w-full flex-col lg:flex-row-reverse">
        <img src={gift} className="rounded-lg" alt="catch-copy" />
        <div className="ml-16">
          <h1 className="text-5xl font-bold">プレゼントキャンペーン中！</h1>
          <p className="py-6">
            いまリージョンハックをご利用いただくと仕事に役立つアイテムをプレゼント中！！
          </p>
        </div>
      </div>
    </div>
  );
};
