import { toast } from "react-toastify";

export type ToastPromiseOptions = {
  pending: string;
  success: string;
  error: string;
};

export const ToastPromiseOptionsDefault = {
  pending: "処理中...",
  success: "成功😄",
  error: "失敗😵",
};

export const toastPromise = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: Promise<any>,
  options: ToastPromiseOptions = ToastPromiseOptionsDefault
) => {
  await toast.promise(callback, options);
};

export const toastSuccess = (message: string) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const toastError = (message: string) => {
  toast.error(message, {
    position: "top-center",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const toastClearAll = (): void => {
  toast.dismiss();
};
