import React from "react";
import Loader from "react-loader-spinner";

type LoadingType =
  | "Audio"
  | "BallTriangle"
  | "Bars"
  | "Circles"
  | "Grid"
  | "Hearts"
  | "Oval"
  | "Puff"
  | "Rings"
  | "TailSpin"
  | "ThreeDots"
  | "Watch"
  | "RevolvingDot"
  | "Triangle"
  | "Plane"
  | "MutatingDots"
  | "CradleLoader";
export type LoadingUIProps = {
  type?: LoadingType;
  color?: string;
  width?: number;
  height?: number;
};

export const LoadingUI: React.FC<LoadingUIProps> = ({
  type = "Puff",
  color = "#34495E",
  width = 80,
  height = 80,
}) => {
  return <Loader type={type} color={color} height={height} width={width} />;
};
