import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { AlertUI } from "../../../../components/Alert";
import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";
import { Gift } from "../gifts/GiftItem";
import Interview from "./interview.svg";
import { Gifts } from "../gifts/Gifts";
import { gtagEvent } from "../../../../types/gtag";

type OpportunityGiftsSectionProps = HTMLAttributes<HTMLDivElement>;

export const OpportunityGiftsSection: React.FC<
  OpportunityGiftsSectionProps
> = ({ ...rest }) => {
  const OpportunityGifts: Gift[] = [
    {
      id: 1,
      title: "アジャイルサムライ――達人開発者への道",
      description:
        "マスターセンセイと学ぶアジャイル開発の道動くソフトウェアを素早く開発するための「アジャイルソフトウェア開発手法」を、実際に導入するにはどうすればよいかを、豊富な図を使い親しみやすい言葉で解説しています。",
      url: "https://amzn.asia/d/fr87Odr",
      src: "https://m.media-amazon.com/images/I/51lm0alurNL.jpg",
    },
    {
      id: 2,
      title:
        "リーダブルコード ―より良いコードを書くためのシンプルで実践的なテクニック",
      description: `本書の目的は、君のコードを良くすることだ」。コードは理解しやすくなければならない。本書はこの原則を日々のコーディングの様々な場面に当てはめる方法を紹介します。`,
      url: "https://amzn.asia/d/11p7KQ9",
      src: "https://m.media-amazon.com/images/I/81+3DpjuMdL.jpg",
    },
    {
      id: 3,
      title: "リファクタリング(第2版): 既存のコードを安全に改善する",
      description: `本書はリファクタリングのガイドブックであり、リファクタリングとは何か、なぜリファクタリングをすべきか、どこを改善すべきか、実際の事例で構成され、ソフトウェア開発者にとって非常に役立つものとなっています。`,
      url: "https://www.amazon.co.jp/dp/4274224546?ref_=cm_sw_r_cp_ud_dp_X8PZV49E7EP3DQNGRDPP",
      src: "https://m.media-amazon.com/images/I/71UUxhdPRfL.jpg",
    },
    {
      id: 4,
      title: "テスト駆動開発",
      description: `本書は、自分たちのコードに自信を持って開発を続けたいプログラマ、チームリーダー向けに、テスト駆動開発(TDD)の実践方法を解説した“Test-Driven Development By Example"の日本語版です。`,
      url: "https://amzn.asia/d/dNDporn",
      src: "https://m.media-amazon.com/images/I/719E-POor7L.jpg",
    },
    {
      id: 5,
      title:
        "ドメイン駆動設計入門 ボトムアップでわかる！ドメイン駆動設計の基本",
      description: `本書は、『エリック・エヴァンスのドメイン駆動設計』（ISBN978-4-7981-2196-3、翔泳社）、『実践ドメイン駆動設計』（ISBN978-4-7981-3161-0、翔泳社）に感銘を受けた著者が贈る、ドメイン駆動設計の入門書です。`,
      url: "https://amzn.asia/d/f8ndMNe",
      src: "https://m.media-amazon.com/images/P/B082WXZVPC.01._SCLZZZZZZZ_SX500_.jpg",
    },
    {
      id: 6,
      title: "Clean Architecture 達人に学ぶソフトウェアの構造と設計",
      description: `アーキテクチャのルールはどれも同じである!書いているコードが変わらないのだから、どんな種類のシステムでもソフトウェアアーキテクチャのルールは同じ。ソフトウェアアーキテクチャのルールとは、プログラムの構成要素をどのように組み立てるかのルールである。構成要素は普遍的で変わらないのだから、それらを組み立てるルールもまた、普遍的で変わらないのである。(本書「序文」より)`,
      url: "https://amzn.asia/d/9GA2EXa",
      src: "https://m.media-amazon.com/images/I/91TJotanDbL.jpg",
    },
  ];

  return (
    <div
      className="px-12 bg-base-100 flex flex-col items-center py-20"
      {...rest}
    >
      <SectionTitle className="underline decoration-orange-400 decoration-4">
        無料相談プレゼント
      </SectionTitle>
      <img src={Interview} className="w-64 h-64" alt="books presents" />
      <AlertUI type="success" className="my-10 text-xl w-10/12">
        エージェントとの無料面談を受けて頂くと、以下の技術書の中から一冊をプレゼント
        <br />
        <Link
          className="link link-primary"
          to="/contact"
          onClick={() => {
            gtagEvent("click_try_contact_btn_in_opportunity_gitfs_section");
          }}
        >
          →問い合わせる
        </Link>
      </AlertUI>
      <Gifts type="book" gifts={OpportunityGifts} />
    </div>
  );
};
