import React, { ButtonHTMLAttributes } from "react";
import tooltipImg from "./tooltip.svg";

export type ButtonProps = {
  buttonColor?: "btn-primary" | "btn-secondary" | "btn-accent" | "btn-info";
  buttonSize?: "btn-xs" | "btn-sm" | "btn-md" | "btn-lg";
  toolTipText?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
  buttonColor = "btn-secondary",
  buttonSize = "btn-lg",
  toolTipText,
  children,
  onClick,
  className,
}) => {
  return (
    <div className={`relative ${className} inline-block ml-28 mt-4`}>
      {toolTipText && (
        <div className="absolute -left-28 -top-8">
          <div className="relative">
            <p className="absolute top-11 left-3 text-black z-10">
              {toolTipText}
            </p>
            <img
              className={`w-28 h-28 -rotate-90`}
              src={tooltipImg}
              alt="tooltipImg"
            />
          </div>
        </div>
      )}
      <button
        className={`btn ${buttonSize} rounded-2xl shadow-lg shadow-gray-400 ${buttonColor}`}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};
