import React, { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { BreadcrumbContextProvider, BreadcrumbUI } from "./Breadcrumb";
import Header from "./Header";
import { SideBarUI } from "./SideBar";
import { useLayoutQuery } from "../generated/graphql";

type LayoutProps = {
  children?: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const { data } = useLayoutQuery();
  return (
    <div className="h-full">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <Header me={data?.me}></Header>
      <div className="flex grow overflow-y-hidden h-full">
        <SideBarUI className="flex-none" me={data?.me}></SideBarUI>
        <div className="grow pl-3 pr-5 pt-5 overflow-y-scroll">
          <BreadcrumbContextProvider>
            <BreadcrumbUI className="mb-2"></BreadcrumbUI>
            {children}
          </BreadcrumbContextProvider>
        </div>
      </div>
    </div>
  );
};

export default Layout;
