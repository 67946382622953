import React from "react";
import { Gift, GiftItem } from "./GiftItem";

type GiftsProps = {
  gifts: Gift[];
  type: "card" | "book";
};

export const Gifts: React.FC<GiftsProps> = ({ gifts, type }) => {
  let className: string;
  switch (type) {
    case "card":
      className =
        "grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 w-10/12";
      break;
    case "book":
      className =
        "grid md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-x-8 gap-y-10 w-10/12";
      break;
  }
  return (
    <div className={className}>
      {gifts.map((gift: Gift, index: number) => (
        <GiftItem key={index} type={type} gift={gift} />
      ))}
    </div>
  );
};
