import React, { useState, useCallback } from "react";
import { SubmitHandler } from "react-hook-form";
import { toastPromise } from "../../utils/toastUtils";
import { ContactPage } from "./ContactPage";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { gtagEvent } from "../../types/gtag";

export enum ErrorCodes {
  codeMisMatchException = "CodeMismatchException",
}

export type ContactInput = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

const ContactPageContainer: React.VFC = () => {
  const [contactInput] = useState<ContactInput>({
    name: "",
    email: "",
    subject: "お問い合わせ",
    message: "",
  });
  const history = useHistory();

  const onContact: SubmitHandler<ContactInput> = useCallback(
    async (input: ContactInput) => {
      try {
        gtagEvent("click_contact_submit_btn");
        await toastPromise(
          axios.post(
            `https://54fl68tq57.execute-api.ap-northeast-1.amazonaws.com/prd/email`,
            input,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              data: input,
            }
          ),
          {
            pending: "お問い合わせ内容を送信中です...",
            success: "お問い合わせの送信が完了しました",
            error: "お問い合わせの送信に失敗しました",
          }
        );
        history.push("/");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error(error);
      }
    },
    [history]
  );

  return <ContactPage contactInput={contactInput} onContact={onContact} />;
};

export default ContactPageContainer;
