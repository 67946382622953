import awsmobile from "../aws-exports";

const GoogleAnalyticsId = {
  dev: "G-4BM2J8FB61",
  prd: "G-K4QFDYP711",
} as const;

const getGoogleAnalyticsId = (): string => {
  const backet = awsmobile.aws_content_delivery_bucket.match(/.*-(\w+)/);
  const env = backet && backet.length === 2 ? backet[1] : "dev";
  return env === "prd" ? GoogleAnalyticsId.prd : GoogleAnalyticsId.dev;
};

export const initializeGA = (): void => {
  document.getElementById("gtag")?.remove();
  document.getElementById("gtagScript")?.remove();

  const importScript = document.createElement("script");
  importScript.id = "gtag";
  importScript.src = `https://www.googletagmanager.com/gtag/js?id=${getGoogleAnalyticsId()}`;
  importScript.async = true;
  document.head.appendChild(importScript);

  // 実行用scriptをheadタグに埋め込み
  const gTagScript = document.createElement("script");
  gTagScript.id = "gtagScript";
  gTagScript.text = `window.dataLayer = window.dataLayer || []
  function gtag() { dataLayer.push(arguments) }
  gtag('js', new Date())
  gtag('config', '${getGoogleAnalyticsId()}')`;
  document.head.appendChild(gTagScript);
};
