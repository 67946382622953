import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { client } from "./lib/apollo-client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./lib/amplify-configure";
import { AuthProvider } from "./hooks/authProvider/authProvider";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <AuthProvider>
          <App />
          <ToastContainer autoClose={2000} />
        </AuthProvider>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
