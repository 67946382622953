import React from "react";

export type Step = {
  name: string;
  checked: boolean;
};

type StepsUIProps = {
  steps: Step[];
};

export const StepsUI: React.FC<StepsUIProps> = ({ steps }) => {
  return (
    <div className="flex justify-center my-3">
      <ul className="steps">
        {steps.map((step: Step, index: number) => (
          <li
            key={index}
            className={`step ${step.checked ? "step-accent" : ""}`}
          >
            {step.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
