import { Card } from "../../../../components/Card/Card";

export type SellingPointItemProps = {
  cardImg: string;
  text: string;
  onClick: () => void;
};

export const SellingPointItem: React.FC<SellingPointItemProps> = ({
  cardImg,
  text,
  onClick,
}) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <Card
        className="bg-accent shadow-none hover:bg-white hover:opacity-60"
        cardImg={cardImg}
      >
        <div className="card-body items-center text-center">
          <span className="link link-primary">{text}</span>
        </div>
      </Card>
    </div>
  );
};
