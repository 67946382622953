import { useHistory } from "react-router-dom";
import { Button } from "../../../../components/Button/ButtonWithToolTip";
import { gtagEvent } from "../../../../types/gtag";
import ImgFreelancer from "./asset/freelancer.svg";

export const CatchCopyComponent: React.FC = () => {
  const history = useHistory();
  return (
    <div className="hero bg-base-100">
      <div className="hero-content max-w-full flex-col lg:flex-row-reverse">
        <img src={ImgFreelancer} className="rounded-lg" alt="catch-copy" />
        <div className="ml-16">
          <h1 className="text-5xl font-bold">
            リモートだからできる。
            <br />
            こころゆたかな暮らし
          </h1>
          <p className="py-6">
            リモートワークの専門家
            リージョンハックなら地方暮らしでも効率的な資産形成と自由な暮らしを両立できます。
          </p>
          <Button
            toolTipText="60秒で簡単"
            className="animate-bounce"
            onClick={() => {
              gtagEvent("click_try_contact_btn_in_catch_copy");
              history.push("contact");
            }}
          >
            無料で相談してみる
          </Button>
        </div>
      </div>
    </div>
  );
};
