import React, { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { PublicHeader } from "../PublicHeader";

type LayoutProps = {
  children?: ReactNode;
};

export const PublicLayout = ({ children }: LayoutProps) => {
  return (
    <div className="h-full">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <div className="relative">
        <PublicHeader />
        {/* ヘッダーの高さ分幅をもうけるDummy Element */}
        <div className="h-24"></div>
        {children}
      </div>
    </div>
  );
};
