import { HTMLAttributes, useCallback } from "react";
import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";
import OneOnOne from "../../../SupportsPage/components/ForTobeFreelancersTabContent/one_on_one.svg";
import RoadSign from "../../../SupportsPage/components/ForTobeFreelancersTabContent/road_sign.svg";
import LookIn from "../../../SupportsPage/components/ForToGetUrbanProjectsTabContent/lookin.svg";
import ImgHeart from "./asset/heart.svg";
import { SupportItem } from "./SupportItem";
import { useHistory } from "react-router-dom";
import { SupportType } from "../../../SupportsPage/SupportsPage";
import { gtagEvent } from "../../../../types/gtag";
import { camelToSnake } from "../../../../utils/textUtils";

type SupportsProps = HTMLAttributes<HTMLDivElement>;

export type SupportItemClickHandler = (supportType: SupportType) => void;

export const Supports: React.FC<SupportsProps> = ({ ...rest }) => {
  const history = useHistory();
  const items = [
    {
      cardImg: OneOnOne,
      title: "現役フリーランスエンジニアとの1on1",
      text: "フリーランス転向を考えている方向けのサービスです。現役のフリーランスエンジニアと直接会話することで、フリーランスの仕事についてより具体的にイメージでき、将来のキャリアについて方向性を見いだすことができます。",
      supportType: SupportType.ForToBeFreelancers,
    },
    {
      cardImg: RoadSign,
      title: "キャリアアドバイス",
      text: "キャリアにおいて迷っている方に向けたサービスです。専門のアドバイザーが、あなた自身のスキルや価値を高める方法、どのような仕事が自分に合っているのかなど、丁寧にお答えします。自分自身の成長に繋がるキャリアの選択を、専門家に相談してみませんか。",
      supportType: SupportType.ForToBeFreelancers,
    },
    {
      cardImg: LookIn,
      title: "都心案件チラ見せサービス",
      text: "フリーランスエンジニアに向けた案件情報サービスです。都心で高単価の案件を、チラ見せ形式でお届けします。自分に合った案件を見つけることができるため、仕事選びの幅が広がります。単価を上げたい方、都心で仕事をしたい方には、ぜひおすすめのサービスです。",
      supportType: SupportType.ForToGetUrbanProjects,
    },
  ];

  const onClick: SupportItemClickHandler = useCallback(
    (supportType: SupportType) => {
      gtagEvent(`click_${camelToSnake(supportType)}_link`);
      history.push("/supports", { supportType: supportType });
    },
    [history]
  );

  return (
    <div
      className="px-12 bg-base-100 pt-6 flex flex-col items-center pb-12"
      {...rest}
    >
      <img className="w-48 h-48" src={ImgHeart} alt="安心" />
      <SectionTitle>専任スタッフによる安心サポート</SectionTitle>
      <div className="flex flex-wrap justify-center w-full gap-16">
        {items.map((item, index) => (
          <SupportItem
            key={`item_${index}`}
            cardImg={item.cardImg}
            title={item.title}
            text={item.text}
            supportType={item.supportType as SupportType}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
};
