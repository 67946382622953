import { SellingPointItem } from "./SellingPointItem";
import ImgSaving from "./asset/savings.svg";
import ImgHeavenly from "./asset/heavenly.svg";
import ImgFamily from "./asset/family.svg";
import imgWorkingFromAnywere from "./asset/working_from_anywhere.svg";
import { useHistory } from "react-router-dom";
import { SellingPointType } from "../../../SellingPointPage/SellingPointPage";
import { useCallback } from "react";
import { camelToSnake } from "../../../../utils/textUtils";
import { gtagEvent } from "../../../../types/gtag";

export type SellingPointItemClickHandler = (
  sellingPointType: SellingPointType
) => void;

export const SellingPoint: React.FC = () => {
  const items = [
    {
      cardImg: ImgSaving,
      text: "効率的な資産形成",
      type: SellingPointType.EfficientAssetBuilding,
    },
    {
      cardImg: ImgHeavenly,
      text: "自然に囲まれたおちついた暮らし",
      type: SellingPointType.SereneLife,
    },
    {
      cardImg: ImgFamily,
      text: "大切な人との時間",
      type: SellingPointType.TimeWithLoved,
    },
    {
      cardImg: imgWorkingFromAnywere,
      text: "ワーケーション",
      type: SellingPointType.Workcation,
    },
  ];
  const history = useHistory();

  const onClick: SellingPointItemClickHandler = useCallback(
    (sellingPointType: SellingPointType) => {
      history.push("/selling_point", { sellingPointType: sellingPointType });
    },
    [history]
  );

  return (
    <div className="px-12 bg-accent pt-6">
      <div className="flex flex-col justify-center gap-x-12 w-full lg:flex-row">
        {items.map((item, index) => (
          <SellingPointItem
            key={`item_${index}`}
            cardImg={item.cardImg}
            text={item.text}
            onClick={() => {
              gtagEvent(`click_${camelToSnake(item.type)}_link`);
              onClick(item.type);
            }}
          />
        ))}
      </div>
    </div>
  );
};
