import React from "react";
import { Breadcrumb, useBreadcrumb } from "../../components/Breadcrumb";
import { useEffect } from "react";
import { CatchCopyComponent } from "./components/CatchCopy/CatchCopy";
import { SellingPoint } from "./components/SellingPoint/SellingPoint";
import { ProjectIntroduction } from "./components/ProjectIntroduction/ProjectIntroduction";
import { UserVoice } from "./components/UserVoice/UserVoice";
import { Campaign } from "./components/Campaign/Campaign";
import { Registration } from "./components/Registration/Registration";
import { Reasons } from "./components/Reason/Reasons";
import { Supports } from "./components/Supports/Supports";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
];

export const LandingPage: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return (
    <div>
      <CatchCopyComponent />
      <SellingPoint />
      <ProjectIntroduction id="introduction" />
      <UserVoice id="user-voice" />
      <Registration />
      <Supports id="supports" />
      <Campaign />
      <Reasons id="reasons" />
      <Registration />
    </div>
  );
};
