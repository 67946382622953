import React, { useState, useCallback } from "react";
import { SignUpPage } from "./SignUpPage";
import { SignUpInput } from "../../hooks/authProvider/authProvider";
import { SubmitHandler } from "react-hook-form";
import { toastError } from "../../utils/toastUtils";

export enum ErrorCodes {
  userNameExistsException = "UsernameExistsException",
  invalidPasswordException = "InvalidPasswordException",
  invalidParameterException = "InvalidParameterException",
  codeMisMatchException = "CodeMismatchException",
  codeDeliveryFailureException = "CodeDeliveryFailureException",
  notAuthorizedException = "NotAuthorizedException",
}

const SignUpPageContainer: React.VFC = () => {
  const [signUpInput] = useState<SignUpInput>({
    familyName: "",
    givenName: "",
    familyNameKana: "",
    givenNameKana: "",
    email: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    tel: "",
    job: "",
    prefecture: "",
    password: "",
  });

  const onSignUp: SubmitHandler<SignUpInput> = useCallback(
    async (input: SignUpInput) => {
      try {
        // TODO: 確認画面にデータ渡せるようになったら削除する
        console.log(JSON.stringify(input, null, 2));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toastError(error.message);
      }
    },
    []
  );

  return <SignUpPage signUpInput={signUpInput} onSignUp={onSignUp} />;
};

export default SignUpPageContainer;
