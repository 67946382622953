import { HTMLAttributes } from "react";
import { SectionTitle } from "../../../../components/SectionTitle/SectionTItle";
import {
  ProjectIntroductionItem,
  ProjectIntroductionItemProps,
} from "./ProjectIntroductionItem";
import ImgWorking from "./asset/working.svg";

type ProjectIntroductionProps = HTMLAttributes<HTMLDivElement>;

export const ProjectIntroduction: React.FC<ProjectIntroductionProps> = ({
  ...rest
}) => {
  const projectItems: ProjectIntroductionItemProps[] = [
    {
      projectName: "Androidエンジニア募集",
      price: "80万〜90万",
      tags: [
        "Androidアプリエンジニア",
        "Kotlin",
        "Java",
        "ruby on rails",
        "フルリモート",
      ],
    },
    {
      projectName: "ストリーミングサービスの開発",
      price: "70万〜85万",
      tags: [
        "フロントエンドエンジニア",
        "React",
        "BrightCovePlayer",
        "フルリモート",
      ],
    },
    {
      projectName: "UIデザイナー募集",
      price: "70万〜90万",
      tags: [
        "UIデザイナー",
        "Figma",
        "Adobe CC",
        "フルリモート・出勤選択可(渋谷)",
      ],
    },
    {
      projectName: "IoT機器開発エンジニア募集",
      price: "90万〜100万",
      tags: [
        "フルスタックエンジニア",
        "AWS(EC2,ECS,Aurora(mysql),S3)",
        "RHEL",
        "python",
        "java",
        "基本リモート・神田",
      ],
    },
    {
      projectName: "Dart/Flutterエンジニア募集",
      price: "70万〜80万",
      tags: ["アプリエンジニア", "Dart/Flutter", "Swift", "横浜駅"],
    },
  ];

  return (
    <div
      className="px-12 bg-base-100 pt-6 flex flex-col items-center pb-12"
      {...rest}
    >
      <img className="w-48 h-48" src={ImgWorking} alt="案件紹介" />
      <SectionTitle>案件の紹介</SectionTitle>
      <div className="flex flex-wrap w-full gap-x-12 gap-y-6 justify-center">
        {projectItems.map((item, index) => (
          <ProjectIntroductionItem
            key={`item_${index}`}
            projectName={item.projectName}
            price={item.price}
            tags={item.tags}
          />
        ))}
      </div>
    </div>
  );
};
