export const toInteger = (value?: number) => {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    return +value;
  }
  return value;
};

export const omitText = (text?: string | null, length = 10) => {
  if (!text) {
    return "";
  }
  return text.length > length ? `${text.slice(0, length)}...` : text;
};

export const camelToSnake = (
  camelCase: string,
  startWithUnderscore = false
): string => {
  const snakeCase = camelCase
    .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    .replace(/^_/, "");
  return startWithUnderscore ? `_${snakeCase}` : snakeCase;
};
