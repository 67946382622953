import React, { HTMLAttributes } from "react";
import { ReactComponent as InfoIcon } from "./info.svg";
import { ReactComponent as SuccessIcon } from "./success.svg";

type AlertUIProps = {
  type: "info" | "success";
} & HTMLAttributes<HTMLDivElement>;

export const AlertUI: React.FC<AlertUIProps> = ({
  type,
  children,
  className,
}) => {
  return (
    <div
      className={`alert ${
        type === "info" ? "alert-info" : "alert-success"
      } shadow-lg text-white ${className}`}
    >
      <div>
        {type === "info" && <InfoIcon />}
        {type === "success" && <SuccessIcon />}
        {children}
      </div>
    </div>
  );
};
