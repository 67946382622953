import React, { HTMLAttributes } from "react";
import { SideBarMenuUI } from "./SideBarMenu";
import { LayoutMeFragment } from "../../generated/graphql";

export type SideBarUIProps = {
  me?: LayoutMeFragment;
} & HTMLAttributes<HTMLDivElement>;

export const SideBarUI: React.FC<SideBarUIProps> = ({ me }) => {
  return (
    <div className="flex overflow-hidden bg-white">
      <div className="hidden md:flex md:shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col grow pt-5 overflow-y-auto bg-gradient-to-r from-sidebar-left to-sidebar-right border-r">
            <div className="flex flex-col items-center shrink-0 px-4">
              <a
                href="./index.html"
                className="px-8 text-left focus:outline-none"
              >
                <h2 className="block p-2 text-xl font-medium tracking-wider text-white transition duration-500 ease-in-out cursor-pointer hover:text-white">
                  Region Hack
                </h2>
              </a>
            </div>
            <SideBarMenuUI me={me} />
            {/* <div className="flex shrink-0 p-4 px-4 bg-indigo-600">
              <a
                href="https:google.com"
                className="shrink-0 block w-full group"
              >
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block rounded-full h-9 w-9"
                      src="/assets/images/avatar.png"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">
                      Wicked LAbs
                    </p>
                  </div>
                </div>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
