import { ButtonUI } from "../../../../components/Button";
import { Card } from "../../../../components/Card/Card";
import { SupportType } from "../../../SupportsPage/SupportsPage";
import { SupportItemClickHandler } from "./Supports";

export type SupportItemProps = {
  cardImg: string;
  title: string;
  text: string;
  supportType: SupportType;
  onClick: SupportItemClickHandler;
};

export const SupportItem: React.FC<SupportItemProps> = ({
  cardImg,
  title,
  text,
  supportType,
  onClick,
}) => {
  return (
    <Card className="w-96" cardImg={cardImg}>
      <div className="card-body items-center text-center">
        <h2 className="card-title">{title}</h2>
        <p>{text}</p>
      </div>
      <div className="flex justify-end mr-5 mb-5">
        <ButtonUI
          buttonType="primary"
          isOutline={true}
          onClick={() => {
            onClick(supportType);
          }}
        >
          詳細
        </ButtonUI>
      </div>
    </Card>
  );
};
