import React, { useState } from "react";
import SideBarMenuItemUI from "./SideBarMenuItem";
import { LayoutMeFragment } from "../../generated/graphql";
import { isAllowDevelopUse } from "../../utils/authorityUtils";

interface SideBarMenuProps {
  me?: LayoutMeFragment;
}

interface Menu {
  id: string;
  title: string;
  to: string;
  display: boolean;
}

export const SideBarMenuUI: React.FC<SideBarMenuProps> = ({ me }) => {
  const organizationId = me?.organizationId || "";
  const menus: Menu[] = [
    {
      id: "emailsSearch",
      title: "メール検索",
      to: "/emails/search",
      display: true,
    },
    {
      id: "groups",
      title: "グループ一覧",
      to: "/groups",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "emailNew",
      title: "配信メール作成",
      to: "/email/new",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "emailsHistories",
      title: "配信履歴",
      to: "/emails/histories",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "companies",
      title: "会社一覧",
      to: "/companies",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "employees",
      title: "社員一覧",
      to: "/employees",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "engineers",
      title: "エンジニア管理",
      to: "/",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "bulk",
      title: "一括登録",
      to: "/settings/bulk",
      display: isAllowDevelopUse(organizationId),
    },
  ];

  const [openedIndex, setOpenedIndex] = useState<number | undefined>();

  const handleOnClickMenuItem = (open: boolean, index: number) => {
    setOpenedIndex(open ? index : undefined);
  };

  return (
    <div className="flex flex-col grow px-4 mt-5">
      <nav className="flex-1 space-y-1 bg-gradient-to-r from-sidebar-left to-sidebar-right">
        <ul>
          {menus
            .filter((menu) => menu.display)
            .map((menu, index) => (
              <>
                {menu.id === "emailsSearch" && (
                  <p
                    key={`emailsSearch_${index}`}
                    className="px-4 pt-4 font-medium text-white"
                  >
                    メール検索
                  </p>
                )}
                {menu.id === "groups" && (
                  <p
                    key={`groups_${index}`}
                    className="px-4 pt-4 font-medium text-white"
                  >
                    メール配信
                  </p>
                )}
                {menu.id === "companies" && (
                  <p
                    key={`companies_${index}`}
                    className="px-4 pt-4 font-medium text-white"
                  >
                    設定
                  </p>
                )}
                <SideBarMenuItemUI
                  key={index}
                  id={menu.id}
                  title={menu.title}
                  to={menu.to}
                  selected={openedIndex === index}
                  onClickMenuItem={(open) => {
                    handleOnClickMenuItem(open, index);
                  }}
                />
              </>
            ))}
        </ul>
      </nav>
    </div>
  );
};
