import React, { HTMLAttributes } from "react";
import { Support, SupportCard } from "../SupportCard";
import LookIn from "./lookin.svg";
import SelectOptions from "./select_options.svg";

type ForToGetUrbanProjectsTabContentProps = HTMLAttributes<HTMLDivElement>;

export const ForToGetUrbanProjectsTabContent: React.FC<
  ForToGetUrbanProjectsTabContentProps
> = ({ className }) => {
  const supports: Support[] = [
    {
      id: 7,
      title: "都心案件チラみせサービス",
      description:
        "「都心の案件はどれくらいのスキルがあると報酬はいくらもらえるのか」をイメージしていただくために日々アップデートされている名前の案件情報の一部をお見せします。クライアントの社名など、機密情報はマスキングさせて頂くのですが、現在ご参画されている案件と比べてどれくらい違いがあるのかを参考にすることができます。",
      src: LookIn,
    },
    {
      id: 8,
      title: "報酬アップ試算サービス",
      description:
        "現在のあなたのスキルで弊社の案件に参画頂くと、年収がどれくらいアップするのかを試算できます。弊社の案件検索システムを使い、直近5日間の案件を検索して、リアルタイムで単価の相場感を掴んでいただけます。もし事前にあなたのスキルソートを共有頂ける場合は、専任のスタッフが分析し、より精度の高い試算額を共有することができます。",
      src: SelectOptions,
    },
  ];
  return (
    <div className={`${className}`}>
      {supports.map((support: Support, index: number) => (
        <SupportCard key={index} support={support} />
      ))}
    </div>
  );
};
