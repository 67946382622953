import { useHistory } from "react-router-dom";
import { Button } from "../../../../components/Button/ButtonWithToolTip";
import { gtagEvent } from "../../../../types/gtag";

export const Registration: React.FC = () => {
  const history = useHistory();
  return (
    <div className="hero py-20 bg-accent">
      <div className="hero-content text-center">
        <div className="x-full">
          <h1 className="text-5xl font-bold mb-6">
            些細なことでもエージェントが回答します
            <br />
            まずは気軽にご相談ください！
          </h1>
          <Button
            toolTipText="60秒で簡単"
            className="animate-bounce"
            onClick={() => {
              gtagEvent("click_try_contact_btn_in_registration");
              history.push("contact");
            }}
          >
            無料で相談してみる
          </Button>
        </div>
      </div>
    </div>
  );
};
