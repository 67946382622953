import React from "react";
import { useLocation } from "react-router";
import { SellingPointPage, SellingPointType } from "./SellingPointPage";

const SellingPointPageContainer: React.VFC = () => {
  const { state } = useLocation<{
    sellingPointType: SellingPointType | undefined;
  }>();
  return <SellingPointPage initSellingPointType={state?.sellingPointType} />;
};

export default SellingPointPageContainer;
