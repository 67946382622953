export type UserVoiceItemProps = {
  icon: string;
  title: string;
  text: string;
  profile: string;
}


export const UserVoiceItem: React.FC<UserVoiceItemProps> = ({icon, title, text, profile}) => {
  return (
    <div className="hero py-4">
      <div className="hero-content flex-col lg:flex-row">
        <img src={icon} alt="利用者アイコン" className="max-h-80 rounded-lg shadow-2xl mask mask-circle" />
        <div>
          <h1 className="text-3xl font-bold">{title}</h1>
          <div className="mt-6 text-blue-700">{profile}</div>
          <p className="mb-6 leading-10">{text}</p>
        </div>
      </div>
    </div>
  )
}
