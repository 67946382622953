import { Link } from "react-router-dom";
import { gtagEvent } from "../../../types/gtag";

const FAQButton = () => (
  <Link
    className="bg-white border border-red-400 text-red-400 mr-4 px-2 h-12 flex items-center"
    to={`/questions`}
    onClick={() => {
      gtagEvent("click_questions_link");
    }}
  >
    よくあるご質問
  </Link>
);

export default FAQButton;
